import { React } from "react";
import { useTracking } from "react-tracking";
import PropTypes from "prop-types";

const homepage = process.env.REACT_APP_VIRTUAL_PATH;

const LinkButton = ({
  icon,
  title,
  url,
  captureEvents,
  className,
  isEventCancelable = false,
  onClickButton,
  iconPosition = "left",
  iconComponent,
  ariaLabel,
  ...rest
}) => {
  const { trackEvent } = useTracking();

  return (
    <a
      onClickCapture={(e) => {
        e.stopPropagation();

        setTimeout(() => {
          // Simulating an async operation
          captureEvents && trackEvent(captureEvents);
        }, 300); // Replace with your actual tracking logic

        if (isEventCancelable) {
          e.preventDefault();
        }

        if (onClickButton) {
          onClickButton(e);
        }
      }}
      href={url}
      role="button"
      aria-label={ariaLabel}
      {...rest}
    >
      <div className={"btn btn-bullseye rounded-1 px-2 " + className}>
        {iconComponent && iconComponent}
        {icon && icon.icon && !iconComponent && (
          <svg role="img" width={icon.size ?? 20} height={icon.size ?? 20}>
            <use
              xlinkHref={`${homepage}images/b-icons/bootstrap-icons.svg#${icon.icon}`}
            />
          </svg>
        )}
        {iconPosition === "top" ? <br></br> : null}
        <span
          className="bl-text-bold-500"
          style={icon && icon.icon && { fontSize: "85%" }}
        >
          {iconPosition === "top" ? title : " " + title}
        </span>
      </div>
    </a>
  );
};
LinkButton.propTypes = {
  /** label title of the card */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /** url address to the click */
  url: PropTypes.string,
  /** function to save the log click */
  captureEvents: PropTypes.object,
};
export default LinkButton;
