import { NewId } from "../../../utils/utils";
import optionalListIcon_1 from "./assets/images/1.svg";
import optionalListIcon_2 from "./assets/images/2.svg";
import optionalListIcon_3 from "./assets/images/3.svg";

import optionalListIcon_1_blue from "./assets/images/1_blue.svg";
import optionalListIcon_2_blue from "./assets/images/2_blue.svg";
import optionalListIcon_3_blue from "./assets/images/3_blue.svg";

export const getAttribute = (attributes, attributeName) => {
  if (!attributes || attributes.length === 0) return null;
  if (!attributeName) return null;

  const attribute = attributes.find(
    (attr) => attr.AttributeName?.toLowerCase() === attributeName.toLowerCase()
  );

  if (attribute) {
    return {
      displayText: attribute.AttributeDisplayText,
      value: attribute.AttributeValue,
      id: attribute.AttributeId,
    };
  }

  return null;
};

export const getBlogs = (complexAttributes) => {
  if (!complexAttributes) return [];

  const result = complexAttributes
    .filter((attr) => attr.AttributeName === "Blog")
    .map((attr) => {
      const title = attr.SubAttributes.find(
        (subAttr) => subAttr.AttributeName === "Title"
      );

      if (title && title.AttributeValue) {
        const blogLink = attr.SubAttributes.find(
          (subAttr) => subAttr.AttributeName === "Blog link"
        );

        return {
          Id: NewId(),
          Title: title.AttributeValue,
          BlogLink: blogLink ? blogLink.AttributeValue : null,
        };
      }
      return null;
    })
    .filter((blog) => blog !== null);

  return result;
};

export const getEvents = (events) => {
  if (!events) return [];

  const path = window.location.pathname;

  const secondSlashIndex = path.indexOf("/", path.indexOf("/") + 1);
  const urltmp = path.substring(secondSlashIndex + 1);

  const thirdSlashIndex = urltmp.indexOf("/", urltmp.indexOf("/") + 1);
  const url = urltmp.substring(thirdSlashIndex + 1);

  const result = events.map((attr) => {
    const EventId = attr.EventId;
    const EventTitle = attr.EventTitle;
    const EndDate = attr.EndDate;
    const EndTime = attr.EndTime;
    const StartDate = attr.StartDate;
    const StartTime = attr.StartTime;
    const urlEvent = attr.RegistrationURL;

    const eventURL =
      process.env.REACT_APP_BUL_EVENT_URL +
      process.env.REACT_APP_INTERFACE_NAME +
      "/" +
      url +
      "/events/" +
      EventTitle +
      "-" +
      EventId;

    return {
      EventId: EventId,
      EndDate: EndDate,
      EndTime: EndTime,
      EventTitle: EventTitle,
      StartDate: StartDate,
      StartTime: StartTime,
      urlEvent: urlEvent ? urlEvent : eventURL,
    };
  });

  return result;
};

export const getOptionalServices = (attributes, cssColor) => {
  const mainOptional = [];
  const optionalSubHeading1 = getAttribute(
    attributes,
    "Optional Heading1"
  )?.value;
  const optionalSubHeading2 = getAttribute(
    attributes,
    "Optional Heading2"
  )?.value;
  const optionalSubHeading3 = getAttribute(
    attributes,
    "Optional Heading3"
  )?.value;

  const linksOptional1 = attributes
    .filter((x) => x.AttributeName?.toLowerCase().includes("optional l1"))
    .map((x) => ({
      url: "#",
      title: x.AttributeValue,
    }));
  const linksOptional2 = attributes
    .filter((x) => x.AttributeName?.toLowerCase().includes("optional l2"))
    .map((x) => ({
      url: "#",
      title: x.AttributeValue,
    }));
  const linksOptional3 = attributes
    .filter((x) => x.AttributeName?.toLowerCase().includes("optional l3"))
    .map((x) => ({
      url: "#",
      title: x.AttributeValue,
    }));

  if (optionalSubHeading1 && linksOptional1.length > 0) {
    mainOptional.push({
      id: 1,
      iconUrl:
        cssColor == "#C2002F" ? optionalListIcon_1 : optionalListIcon_1_blue,
      iconSize: { x: 125, y: 125 },
      title: optionalSubHeading1,
      links: linksOptional1,
    });
  }
  if (optionalSubHeading2 && linksOptional2.length > 0) {
    mainOptional.push({
      id: 2,
      iconUrl:
        cssColor == "#C2002F" ? optionalListIcon_2 : optionalListIcon_2_blue,
      iconSize: { x: 125, y: 125 },
      title: optionalSubHeading2,
      links: linksOptional2,
    });
  }
  if (optionalSubHeading3 && linksOptional3.length > 0) {
    mainOptional.push({
      id: 3,
      iconUrl:
        cssColor == "#C2002F" ? optionalListIcon_3 : optionalListIcon_3_blue,
      iconSize: { x: 125, y: 125 },
      title: optionalSubHeading3,
      links: linksOptional3,
    });
  }
  return mainOptional;
};

const getLastNumber = (data) => {
  let lastNumber = 0;
  for (const obj of data) {
    const attributeName = obj.AttributeName;
    const match = attributeName.match(/(\d+)$/);
    if (match) {
      const number = parseInt(match[1]);
      if (number > lastNumber) {
        lastNumber = number;
      }
    }
  }
  return lastNumber;
};

export const getTestimonials = (attributes) => {
  if (!attributes || attributes.length === 0) return null;

  const testimonialGroup = [];
  const testimonialString = "testimonial";

  const lastnumber = getLastNumber(
    attributes.filter((item) =>
      item.AttributeName?.toLowerCase().includes(testimonialString)
    )
  );

  for (let i = 1; i <= lastnumber; i++) {
    const testimonialByline = attributes.find(
      (attr) =>
        attr.AttributeName?.toLowerCase() === `${testimonialString} ${i} byline`
    );
    const testimonialText = attributes.find(
      (attr) =>
        attr.AttributeName?.toLowerCase() === `${testimonialString} ${i}`
    );
    const testimonialImage = attributes.find(
      (attr) =>
        attr.AttributeName?.toLowerCase() === `${testimonialString} image ${i}`
    );
    if (testimonialText) {
      testimonialGroup.push({
        id: NewId(),
        description: testimonialText.AttributeValue,
        author: testimonialByline ? testimonialByline.AttributeValue : "",
        image: testimonialImage ? testimonialImage.AttributeValue : "",
      });
    }
  }
  return testimonialGroup;
};
