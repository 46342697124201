import React, { useEffect, useState } from "react";
import DynamicForm from "../dynamicForm/dynamicForm";
import {
  saveLeadFormConfiguration,
  getLeadFormConfiguration,
  getLeadSources,
} from "../../services/restLeadForm";

import {
  getCategoryTree,
  getAllCountries,
  GetCountryStatesList,
} from "../../services/restSearchService";
import {
  COUNTRIES_LIST_IDS,
  FORM_SUBMISSION_TYPES,
} from "../../utils/constants";
import { useLocation } from "react-router-dom";
import {
  buildSearchParameters,
  getQueryStringParam,
} from "../../utils/bullseyeUtils";
import { setSecurityParameters } from "../../services/httpService";
import BullseyeLoader from "../bullseyeLoader/bullseyeLoader";
import { useTracking } from "react-tracking";
import * as Sentry from "@sentry/react";

const FormLead = ({
  formId,
  locationIds,
  title,
  subTitle = "",
  extraTitle = "",
  small = true,
  compact = true,
  GoogleCaptchaKey,
  clientId,
  apiKey,
  hasContainerSetted = false,
  selectedCountry,
  interfaceId,
  setContentBlock,
  onFormSubmit,
}) => {
  /** configuration for the formLead */
  const [
    countriesAndStatesListForFormLead,
    setCountriesAndStatesListForFormLead,
  ] = useState({
    selectedCountryId: selectedCountry ?? 1,
    countries: [
      {
        value: "",
        name: "Select Country",
      },
    ],
    states: [],
    labels: [
      {
        countryId: COUNTRIES_LIST_IDS.USA,
        labelState: "State",
        labelZipCode: "Zip Code",
      },
      {
        countryId: COUNTRIES_LIST_IDS.CANADA,
        labelState: "Province",
        labelZipCode: "Postal Code",
      },
      {
        countryId: COUNTRIES_LIST_IDS.UK,
        labelState: "Country",
        labelZipCode: "Postcode",
      },
      {
        countryId: COUNTRIES_LIST_IDS.NO_COUNTRY,
        labelState: "State",
        labelZipCode: "Postal Code",
      },
    ],
  });
  const [formLeadConfiguration, setFormLeadConfiguration] = useState({
    loading: true,
  });
  const [cagetoriesTree, setCagetoriesTree] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadFormError, setLeadFormError] = useState({
    hasErrors: false,
    errorMessage: "",
  });

  function getNameAttributeToPostData(oldName) {
    let newName = oldName;

    switch (oldName.toLowerCase()) {
      case "email":
        newName = "EmailAddress";
        break;
      case "company":
        newName = "CompanyName";
        break;
      default:
        // console.log("No Name Change");
        break;
    }
    return newName;
  }
  function generatedDataToPost(data) {
    let orderedData = { Attributes: [], Categories: [] };
    for (const [key, value] of Object.entries(data)) {
      let field = formLeadConfiguration.fields.find(
        (el) => el.id.toString() === key
      );
      let valueTransformed = false;
      if (value && value.length > 0) {
        switch (field?.inputOptionName.toLowerCase()) {
          case "categories":
            if (value) {
              orderedData.Categories = value.split(",").map((item) => {
                return { CategoryID: parseInt(item, 10) };
              });
            }

            valueTransformed = true;
            break;
          case "checkboxlist":
            // this for multiple option selected
            if (Array.isArray(value)) {
              value.forEach((y) => {
                const id = field.options.find((x) => x.name === y)?.id;
                if (id) {
                  const newAttribute = {
                    Id: field.id,
                    AttributeId: field.attributeId,
                    TextValue: null,
                    NumericValue: null,
                    MemoValue: null,
                    BoolValue: null,
                    OptionID: id,
                  };

                  orderedData.Attributes.push(newAttribute);
                }
              });
            } else {
              const id = field.options.find((x) => x.name === value)?.id;

              if (id) {
                const newAttribute = {
                  Id: field.id,
                  AttributeId: field.attributeId,
                  TextValue: null,
                  NumericValue: null,
                  MemoValue: null,
                  BoolValue: null,
                  OptionID: id,
                };

                orderedData.Attributes.push(newAttribute);
              }
            }

            valueTransformed = true;
            break;
          default:
            break;
        }
        if (!valueTransformed) {
          if (field?.attributeId) {
            const defaultAttribute = {
              Id: field.id,
              AttributeId: field.attributeId,
              TextValue: null,
              NumericValue: null,
              MemoValue: null,
              BoolValue: null,
              OptionID: null,
            };
            let newAttribute = {};

            switch (field.inputOptionName.toLowerCase()) {
              case "dropdown":
              case "radiogroup":
                newAttribute = {
                  ...defaultAttribute,
                  OptionID:
                    field?.options?.length > 0 && value
                      ? field.options.find((x) => x.name === value)?.id
                      : null,
                };

                break;
              case "text":
                newAttribute = { ...defaultAttribute, TextValue: value };
                break;
              case "numeric":
                newAttribute = {
                  ...defaultAttribute,
                  NumericValue: value ? parseInt(value) : null,
                };
                break;
              case "memo":
              case "comments":
                newAttribute = { ...defaultAttribute, MemoValue: value };
                break;
              case "yesno":
                newAttribute = {
                  ...defaultAttribute,
                  BoolValue: value
                    ? value.toLowerCase() === "yes"
                      ? "true"
                      : "false"
                    : null,
                };

                break;
              default:
                break;
            }

            orderedData.Attributes.push(newAttribute);
          } else if (field) {
            let nameInput = getNameAttributeToPostData(field.inputOptionName);
            orderedData[nameInput] = value;
          } else {
            orderedData[key] = value;
          }
        }
      }
    }
    return orderedData;
  }

  let location = useLocation();
  const qsLeadSource = getQueryStringParam(location.search, "leadsource");

  const onSubmitFormLead = (data) => {
    let newLead = generatedDataToPost(data);
    let leadSource = {
      key: formLeadConfiguration.leadSourceID,
      name: formLeadConfiguration.leadSourceName,
    };

    if (qsLeadSource) {
      const leadSourceDb = leadSources.find(
        (l) => l.Value.toLowerCase() === qsLeadSource.toLowerCase()
      );

      if (leadSourceDb) {
        leadSource = { key: leadSourceDb.Key, name: leadSourceDb.Value };
      }
    }

    newLead.LeadSourceID = leadSource.key;
    newLead.LeadEmailTemplateId = formLeadConfiguration.leadEmailTemplateId;
    newLead.LeadTypeID = formLeadConfiguration.leadTypeID;
    newLead.PriorityID = 3;
    // newLead.ServiceID = formLeadConfiguration.serviceID;
    newLead.FormID = formId;
    // newLead.InterfaceID = interfaceId;
    newLead.LeadSourceName = leadSource.name;
    // newLead.LeadTextTemplateId = formLeadConfiguration.leadTextTemplateId;
    // newLead.EmailLead = formLeadConfiguration.emailLead;

    const dataForSubmit = {
      ClientId: clientId,
      ApiKey: apiKey,
      LocationId:
        locationIds && locationIds.length === 1 ? locationIds[0] : null,
      LocationIds:
        locationIds && locationIds.length > 1 ? locationIds.join(",") : null,
      NewLead: newLead,
      EmailLead: formLeadConfiguration.emailLead,
      EmailDealers: formLeadConfiguration.emailRoutedTo,
      ConfirmationLeadEmailCode: null,
      NewLeadEmailCode: formLeadConfiguration.emailCode,
      NewLeadTextCode: null,
      // InterfaceID: interfaceId,
    };
    const { formSubmissionTypeID, submissionURL } = formLeadConfiguration;
    /** depends the kind of the formSumissionType the action of the submit */
    saveLeadFormConfiguration(dataForSubmit);

    trackEvent({
      additionalInformation: newLead,
      lead_source: leadSource.name,
    });

    if (onFormSubmit) {
      onFormSubmit(dataForSubmit);
    }

    const openInNewTab = (url) => {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    };

    if (formSubmissionTypeID === FORM_SUBMISSION_TYPES.RedirectURL) {
      openInNewTab(submissionURL);
    }

    if (
      formSubmissionTypeID === FORM_SUBMISSION_TYPES.RedirectEmbeddedInterface
    ) {
      const {
        LeadSourceName: source,
        CountryId,
        City,
        StateAbbr: state,
        PostalCode,
        Categories,
      } = newLead;

      const redirectQueryString = buildSearchParameters("?x=1", {
        source,
        CountryId,
        City: PostalCode ? null : City,
        state: PostalCode ? null : state,
        PostalCode,
        category: Categories.map((x) => x.CategoryID).join(","),
      });

      // countryId, postalCode, state, city, category,source,
      // qsRadius, qsLangCode,qsIp

      openInNewTab(submissionURL + redirectQueryString);
    }
  };

  useEffect(() => {
    const getLeadSourcesAsync = async () => {
      const leadsources = await getLeadSources();
      setLeadSources(leadsources);
    };

    const setLeadFormAsync = async () => {
      let countriesconfig = {
        ...countriesAndStatesListForFormLead,
      };
      if (countriesAndStatesListForFormLead.states.length < 1) {
        const countries = await getAllCountries();

        // I have to set the parameters again because getAllCountries clear the security
        setSecurityParameters(clientId, apiKey);

        /** get list of states for each Contry*/
        const states = await GetCountryStatesList(
          countriesAndStatesListForFormLead.selectedCountryId
        );

        countriesconfig = {
          ...countriesAndStatesListForFormLead,
          loading: false,
          countries: countries.map((country) => {
            return { value: country.id, name: country.name };
          }),
          states: [
            {
              countryId: countriesAndStatesListForFormLead.selectedCountryId,
              list: states.map((x) => {
                return {
                  id: x.Abbr,
                  name: `${x.Abbr} - ${x.Name}`,
                  value: x.Abbr,
                };
              }),
            },
          ],
        };

        setCountriesAndStatesListForFormLead(countriesconfig);
      }

      let form;
      try {
        form = await getLeadFormConfiguration(formId);
      } catch (error) {
        setLeadFormError({
          hasErrors: true,
          errorMessage: error.response.statusText,
        });

        Sentry.captureException(error);
      }

      const categories = await getCategoryTree();
      if (categories) {
        setCagetoriesTree(convertCategoriesTreeToInputFieldTree(categories));
      }

      setFormLeadConfiguration({ ...form, loading: false });

      if (setContentBlock && form?.contentBlockEnabled) {
        setContentBlock(form.contentBlockEnabled, form.contentBlockContent);
      }
    };

    getLeadSourcesAsync();
    setLeadFormAsync();
  }, [formId]);

  function createNodeTree(category) {
    return {
      label: category.Name,
      value: category.ID,
      children: category.SubCategories
        ? convertCategoriesTreeToInputFieldTree(category.SubCategories)
        : [],
    };
  }
  function convertCategoriesTreeToInputFieldTree(categoriesTree) {
    return categoriesTree.map((x) => createNodeTree(x));
  }
  const handleCountryChange = async (countryId) => {
    const states = await GetCountryStatesList(countryId);

    setCountriesAndStatesListForFormLead({
      ...countriesAndStatesListForFormLead,
      loading: false,
      selectedCountryId: countryId,
      states: [
        {
          countryId: countryId,
          list: states.map((x) => {
            return {
              id: x.Abbr,
              name: `${x.Abbr} - ${x.Name}`,
              value: x.Abbr,
            };
          }),
        },
      ],
    });
  };

  /** report to the track system the submition */
  const { trackEvent } = useTracking({
    page: window.location.pathname,
    eventCategory: "LeadForm",
    formId: formId,
    event: "submit",
    locationId: locationIds && locationIds[0],
    eventNameGA: "generate_lead",
  });

  return (
    <>
      {formLeadConfiguration.loading && <BullseyeLoader />}
      {!formLeadConfiguration.loading && (
        <DynamicForm
          formLeadConfiguration={formLeadConfiguration}
          title={title}
          subTitle={subTitle}
          extraTitle={extraTitle}
          small={small}
          compact={compact}
          onSubmit={onSubmitFormLead}
          GoogleCaptchaKey={GoogleCaptchaKey}
          countriesAndStatesConfig={countriesAndStatesListForFormLead}
          cagetoriesTree={cagetoriesTree}
          hasContainerSetted={hasContainerSetted}
          onCountryChange={handleCountryChange}
          defaultValues={selectedCountry ? { CountryId: selectedCountry } : {}}
          hasError={leadFormError.hasErrors}
          customErrorMessage={leadFormError.errorMessage}
        />
      )}
    </>
  );
};
export default FormLead;
