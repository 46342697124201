import React from "react";
import "./slider2.scss";
import facebookLogo from "../pages/salesX/assets/images/facebook.png";
import linkedinLogo from "../pages/salesX/assets/images/linkedin.png";
import x from "../pages/salesX/assets/images/x_logo.png";
import { useTracking } from "react-tracking";
import PropTypes from "prop-types";
import { AttributeType } from "../../utils";
import { EVENT_LOG_TYPE, SEARCH_RES_LOG_TYPE } from "../../utils/constants";

/** The header component is full width, where some navigation elements and other content such as the location's logo and image are located.*/
const Slider2 = ({
  title,
  titleParagraph,
  subTitle,
  subTitleParagraph,
  backgroundImage,
  bannerImage,
  logo,
  backgroundOpacy = 0.0,
  linkedIn,
  facebook,
  twitter,
  titleStyle,
}) => {
  const { trackEvent } = useTracking();
  return (
    <div
      className="d-flex flex-column justify-content-end slider__main slider__main--container"
      style={
        backgroundImage
          ? {
              backgroundImage:
                `linear-gradient(rgba(0, 0, 0, ${backgroundOpacy}), rgba(0, 0, 0, ${backgroundOpacy})), url(` +
                backgroundImage +
                ")",
              height: 350,
            }
          : {}
      }
    >
      {bannerImage ? (
        <img className="img-fluid" src={bannerImage} alt={title}></img>
      ) : (
        <div style={{ height: 100 }}></div>
      )}
      <div className="d-block d-sm-none text-center">
        <div>
          {logo && (
            <img
              className="rounded-circle"
              style={{
                height: "200px",
                width: "200px",
                border: "10px solid #fff",
              }}
              src={logo}
              alt={""}
            ></img>
          )}
        </div>
      </div>
      <div className="pt-1 slider__main--infoContainer">
        <div className="d-none d-md-block d-md-none slider__main--logoContainer">
          {logo && <img src={logo} alt={""}></img>}
        </div>
        <div className="p-3 row mx-0">
          <div className="col-md-9 offset-md-3">
            <h1 style={{ ...titleStyle }}>{title}</h1>
            <p className="mb-0">{titleParagraph}</p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#707170",
            color: "#fff",
            minHeight: 165,
          }}
          className="mb-0 p-3 row mx-0"
        >
          <div className="col-md-3 d-flex justify-content-center align-items-end">
            <div>
              {" "}
              {linkedIn.link && (
                <a
                  href={`http://linkedin.com/${linkedIn.link}`}
                  alt="linkedIn"
                  target="_blank"
                  onClickCapture={() =>
                    trackEvent({
                      eventType: EVENT_LOG_TYPE.logSearch,
                      attributeId: linkedIn.id,
                      attributeTypeId: AttributeType.Text, // Link
                      event: "click",
                      eventValue: "linkedin",
                      responseType: SEARCH_RES_LOG_TYPE.attributeClicked,
                    })
                  }
                >
                  <img
                    src={linkedinLogo}
                    alt="LinkedIn Logo White"
                    style={{ width: 48 }}
                    className="mx-2"
                  ></img>
                </a>
              )}
              {twitter.link && (
                <a
                  href={`http://x.com/${twitter.link}`}
                  alt="X"
                  target="_blank"
                  onClickCapture={() =>
                    trackEvent({
                      eventType: EVENT_LOG_TYPE.logSearch,
                      attributeId: twitter.id,
                      attributeTypeId: AttributeType.Text, // Link
                      event: "click",
                      eventValue: "twitter",
                      responseType: SEARCH_RES_LOG_TYPE.attributeClicked,
                    })
                  }
                >
                  <img
                    src={x}
                    alt="X Logo White"
                    style={{ width: 40, height: 40 }}
                  ></img>
                </a>
              )}
              {facebook.link && (
                <a
                  href={`http://facebook.com/${facebook.link}`}
                  alt="facebook"
                  target="_blank"
                  onClickCapture={() =>
                    trackEvent({
                      eventType: EVENT_LOG_TYPE.logSearch,
                      attributeId: facebook.id,
                      attributeTypeId: AttributeType.Text, // Link
                      event: "click",
                      eventValue: "facebook",
                      responseType: SEARCH_RES_LOG_TYPE.attributeClicked,
                    })
                  }
                >
                  <img
                    src={facebookLogo}
                    alt="facebook"
                    style={{ width: 48 }}
                    className="mx-2"
                  ></img>
                </a>
              )}
            </div>
          </div>
          <div className="col-md-9 d-flex flex-column align-items-start justify-content-center">
            <h3 className="mb-0 font-weight-bold">{subTitle}</h3>
            <p
              className="mb-0"
              dangerouslySetInnerHTML={{
                __html: subTitleParagraph,
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};
Slider2.propTypes = {
  /** Title of the card */
  title: PropTypes.string.isRequired,
  /**  URL to the website option*/
  website: PropTypes.string,
  /**  URL to the email option*/
  email: PropTypes.string,
  /**  URL to the direction option*/
  directions: PropTypes.string,
  /**  To use the whole div background with an image */
  backgroundImage: PropTypes.string,
  /** Image that fill only the image place holder */
  bannerImage: PropTypes.string,
  /** Logo fo the company */
  logo: PropTypes.string.isRequired,
  /** Image background to the header */
  backgroundOpacy: PropTypes.number,
};
export default Slider2;
