import React from "react";
import { useTracking } from "react-tracking";
import "./socialMediaIcons.scss";
import PropTypes from "prop-types";

/** Component to display social media icons with their own link address */
const SocialMediaIcons = ({ icons, title, iconBasePath }) => {
  const { trackEvent } = useTracking();
  return (
    icons.length > 0 && (
      <div>
        {title && <div className="bullseye-red h4">{title}</div>}
        <ul className="list-inline mb-0">
          {icons.map((icon) => {
            return (
              <li key={icon.name} className="list-inline-item social-icon">
                <a
                  href={icon.url}
                  alt={icon.name}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={icon.name}
                  onClickCapture={() =>
                    trackEvent({
                      genericResponseType:
                        "Click local page social media " + icon.name,
                      event: "click",
                      eventNameGA:
                        "click_social_" +
                        icon.name
                          .toLowerCase()
                          .replace(/\s/g, "_")
                          .replace(/[^a-zA-Z0-9_]/g, ""),
                      additionalInformation: {
                        social_media_url: icon.url,
                      },
                    })
                  }
                >
                  <svg role="img" className="socialMedia__icon-md">
                    <use
                      href={`${iconBasePath}/images/social-media-icons.svg#${icon.name}`}
                    />
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

SocialMediaIcons.propTypes = {
  /** Title of the card */
  title: PropTypes.string,
  /** object with the icon information */
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      /**name of the boostrap icon */
      name: PropTypes.string.isRequired,
      /** Url to the link icon */
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Relative base path for social media icons */
  iconBasePath: PropTypes.string.isRequired,
};

// Specifies the default values for props:
SocialMediaIcons.defaultProps = {
  iconBasePath: "/local",
};

export default SocialMediaIcons;
