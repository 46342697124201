import React from "react";

const homepage = process.env.REACT_APP_VIRTUAL_PATH;

const NotConfigured = () => {
  return (
    <div className="d-flex">
      <div className="m-5 d-none d-md-block">
        <img
          src={`${homepage}images/bullseye-white-icon.png`}
          alt="bullseye icon white"
        />
      </div>
      <div>
        <div className="my-5 d-block">
          <img
            src={`${homepage}images/bullseye_logo_outlined.svg`}
            alt="bullseye logo"
            style={{ height: 85 }}
          />
        </div>

        <h5 className="bullseye-red">
          The content you are looking for couldn't be found. This may be for one
          of several reasons:
        </h5>

        <p>
          <ol type="1">
            <li>
              The locator interface has been deleted from the Bullseye account
            </li>
            <li>The Bullseye account has been modified to a "Free" account</li>
            <li>The Bullseye account is no longer active</li>
          </ol>
        </p>

        <p>
          If you are the administrator of the site, please{" "}
          <a href="https://app.bullseyelocations.com/Admin/Login.aspx">
            log into
          </a>
          , the Bullseye Admin to add locations.
        </p>
        <p>
          For more information please visit our website at{" "}
          <a href="https://www.bullseyelocations.com">
            https://www.bullseyelocations.com
          </a>
          , or email{" "}
          <a href="mailto:info@bullseyelocations.com">
            info@bullseyelocations.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default NotConfigured;
