import React from "react";
import CardRating from "./cardRating";
import { NewId } from "../../utils/utils";
import PropTypes from "prop-types";

/** Review list component, display a cards list with the comments and reviews made it by users  */
const CardListRating = ({ listRatings, reviewConfiguration }) => {
  return (
    <div className="cardListRating__container">
      {listRatings &&
        listRatings.map((profile) => (
          <CardRating
            key={NewId()}
            {...profile}
            reviewConfiguration={reviewConfiguration}
          />
        ))}
    </div>
  );
};
CardListRating.propTypes = {
  /** List of ratings to display */
  listRatings: PropTypes.arrayOf(
    PropTypes.shape({
      /** Name of the autor of the review */
      author_name: PropTypes.string.isRequired,
      /** date of the comment */
      relative_time_description: PropTypes.string.isRequired,
      /** Number of the rating made by the author. rating goes from 1-5 */
      rating: PropTypes.number.isRequired,
      /** description of the review made by the autor*/
      text: PropTypes.string.isRequired,
      /** service classification list */
      services: PropTypes.arrayOf(
        PropTypes.shape({
          /** Name of the service */
          name: PropTypes.string.isRequired,
          /** Number of the rating made by the author. rating goes from 1-5 */
          rating: PropTypes.number.isRequired,
        })
      ),
    })
  ).isRequired,
};
export default CardListRating;
