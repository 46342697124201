import { useState, FC, ReactNode, useEffect } from "react";
import { useTracking } from "react-tracking";

interface ToggleIconProps {
  ActiveIcon: ReactNode;
  InactiveIcon: ReactNode;
  defaultActiveState: boolean;
  onToggle?: (active: boolean) => void;
  captureEvents?: object;
  forceState?: boolean;
}

const ToggleIcon: FC<ToggleIconProps> = ({
  ActiveIcon,
  InactiveIcon,
  defaultActiveState = false,
  onToggle,
  captureEvents,
  forceState = null,
}) => {
  const { trackEvent } = useTracking();

  const [active, setActive] = useState(defaultActiveState);

  const toggle = () => {
    if (onToggle) onToggle(!active);
    setActive(!active);
  };

  useEffect(() => {
    if (forceState !== null) setActive(forceState);
  }, [forceState]);

  return (
    <span
      role="button"
      onClick={toggle}
      onClickCapture={(e) => {
        setTimeout(() => {
          // Simulating an async operation
          captureEvents && trackEvent(captureEvents);
        }, 300); // Replace with your actual tracking logic
      }}
    >
      {active ? ActiveIcon : InactiveIcon}
    </span>
  );
};
//ToggleIconButton
export default ToggleIcon;
