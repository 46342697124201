import { LocationsState } from ".";
import { Location } from "../../interfaces";

type LocationsActionType =
  | {
      type: "[Locations] - SetLocations" | "[Locations] - ClearLocations";

      payload: LocationsState;
    }
  | {
      type: "[Locations] - BuildLandingPages";
      payload: Location[];
    }
  | {
      type: "[Locations] - SetMapLocations";
      payload: Location[];
    };

export const locationsReducer = (
  state: LocationsState,
  action: LocationsActionType
): LocationsState => {
  switch (action.type) {
    case "[Locations] - SetLocations":
      return {
        ...state,
        ...action.payload,
      };
    case "[Locations] - SetMapLocations":
      return {
        ...state,
        mapLocations: action.payload,
      };
    case "[Locations] - ClearLocations":
      return action.payload;

    case "[Locations] - BuildLandingPages":
      return { ...state, locations: action.payload };

    default:
      return state;
  }
};
