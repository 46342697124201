import http from "./httpService";

const endpointPath = "RestEvent.svc";
const categoryGetMethod = "GetEvent";

function getEventURL(eventId, thirdPartyId) {
  let searchParameter = "";

  if (eventId) {
    searchParameter = `EventId=${eventId}`;
  } else if (thirdPartyId) {
    searchParameter = `ThirdPartyID=${thirdPartyId}`;
  }

  return `${endpointPath}/${categoryGetMethod}?${searchParameter}`;
}

export async function getEvent(eventId, thirdPartyId) {
  const event = await http.get(getEventURL(eventId, thirdPartyId));
  return event.data;
}
