import React from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import track from "react-tracking";

import { LogSearchRsltwReq } from "./services/restSearchService";
import { install, gtag } from "./utils/googleAnalyticsHelper";
import { SEARCH_RES_LOG_TYPE_DESC } from "./utils/constants";
import { SearchProvider } from "./context/searchContext";
import { LanguageProvider } from "./context/language";
import { routes } from "./routes";
import { SearchProvider2 } from "./context/search";
import { LocationsProvider } from "./context/locations";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

function App2() {
  console.log(
    "app start version " + process.env.REACT_APP_RELEASE_VERSION + "..."
  );

  function placeParams(pathRegex, params) {
    let segments = pathRegex.split("/");

    let finalValue = segments
      .map((segment) => {
        let offset = segment.indexOf(":") + 1;

        if (!offset) return segment;

        let key = segment.slice(offset);

        if (key.includes("slug")) {
          let slugSegment = key.split("-");
          let finalSlug = "";
          slugSegment.forEach((item) => {
            if (!finalSlug) {
              finalSlug += params[item.slice(item.indexOf(":") + 1)] + "-";
            } else {
              finalSlug += params[item.slice(item.indexOf(":") + 1)];
            }
          });
          return finalSlug;
        }
        return params[key];
      })
      .join("/");

    return finalValue;
  }

  return (
    <>
      <ToastContainer />
      <Switch>
        {routes.map(
          ({ path, Component, useSearchProvider, cleanPath }, key) => (
            <Route
              key={key}
              path={path}
              render={(props) => {
                const crumbs = routes
                  .filter(({ canonical }) =>
                    props.match.path.includes(canonical)
                  )
                  .map(({ path, cleanPath, ...rest }) => ({
                    path: placeParams(cleanPath, props.match.params),
                    ...rest,
                  }));

                if (useSearchProvider) {
                  return (
                    <LanguageProvider>
                      <SearchProvider>
                        <LocationsProvider>
                          <SearchProvider2>
                            <Component {...props} crumbs={crumbs} />
                          </SearchProvider2>
                        </LocationsProvider>
                      </SearchProvider>
                    </LanguageProvider>
                  );
                } else {
                  return <Component {...props} crumbs={crumbs} />;
                }
              }}
            ></Route>
          )
        )}
      </Switch>
    </>
  );
}

const recordGAEvent = (data) => {
  if (data.event === "pageview") {
    gtag("set", "page_path", data.page);
    gtag("event", "page_view");
  } else {
    switch (data.eventNameGA) {
      case "generate_lead":
        gtag("event", "generate_lead");
        gtag("event", "bullseye_form_lead_submitted", {
          form_id: data.formId,
          locations: data.locationId,
          lead_source: data.lead_source,
        });

        break;
      default:
        let additionalInformation = {
          ...data.extraParameters,
          ...(data.locationId && { location_id: data.locationId }),
          ...(data.locationName && { location_name: data.locationName }),
          ...(data.searchReqLogId && {
            search_request_id: data.searchReqLogId,
          }),
        };

        gtag("event", data.eventNameGA, { ...additionalInformation });
        break;
    }
  }
};

export default track(
  { app: "Bullseye Locations" },
  {
    dispatch: async (data) => {
      if (navigator.userAgent === "ReactSnap") {
        return;
      }

      if (window.bullseyeLocationsConfig?.captureCustomEvents) {
        window.bullseyeLocationsConfig?.captureCustomEvents(data);
      }

      // Sent data to the parent if the parent wants to track the events
      window.top.postMessage(
        {
          type: "bullseye_tracking_data",
          payload: data,
        },
        "*"
      );

      // Google Analytics
      if (data.googleAnalytic) {
        /*const gaArguments = {
          client_storage: "none",
          client_id: data.googleGATrackingCode,
        };*/

        // This code will check if the page is embed into an iframe
        // with the code `window.top` if not we can use a blank GATracking
        // if not the iframe should send the ga clientId to allow tracking
        if (
          data.googleGATrackingCode ||
          (window.top === window && data.event !== "pageview") // This is because we want to track the page view from the parent
        ) {
          // If googleAnalytic code have comma we are convert to array to track
          // several codes at the same time
          if (data.googleAnalytic.includes(",")) {
            // install(data.googleAnalytic.split(","), gaArguments);
            install(data.googleAnalytic.split(","));
          } else {
            install(data.googleAnalytic);
          }

          recordGAEvent(data);
        }

        //}
      }

      if (!data.responseType) return;

      // Bullseye Log Request
      try {
        await LogSearchRsltwReq(
          data.locationId,
          data.responseType,
          data.searchReqLogId,
          data?.attributeId,
          data?.attributeTypeId
        );
      } catch (error) {
        toast.error(
          "Error registering in the log search the " +
            SEARCH_RES_LOG_TYPE_DESC[data.responseType - 1] ?? ""
        );
        console.error(error);
      }
    },
  }
)(App2);
