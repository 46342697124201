import { useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

export function useMutationResize(container, interfaceId) {
  useEffect(() => {
    const containerDiv = container;

    if (!containerDiv) return;

    // Create an observer instance linked to the callback function
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height } = entry.contentRect;
        window.parent.postMessage(`${height + 45}`, "*");
        window.parent.postMessage(
          {
            type: "bullseye_height_resize",
            payload: {
              message: "resize",
              height: `${height + 45}`,
              interface: interfaceId,
            },
          },
          "*"
        );
      }
    });

    // Start observing the target node for resize
    resizeObserver.observe(containerDiv);

    // Later, you can stop observing
    return () => resizeObserver.unobserve(containerDiv);
  }, [container]);
}
