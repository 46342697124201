import React from "react";
import PropTypes from "prop-types";
import StartRating from "./startRating";

/** component to display a summarized version of the reviews that the location has obtained globally
 */
const OverallRating = ({
  showOverallRating,
  rating,
  countReviews,
  allowWriteReviews,
  onClickWriteReviews,
  smallReviews,
  urlMoreReviews,
  sourceTypeName,
}) => {
  return (
    <div className="textHeader mb-1 border-bottom overallRating_container">
      {showOverallRating && (
        <>
          {smallReviews && rating > 0 && (
            <small className="bl-text-bold-500 align-middle mr-1 overallRating_ratingNumber">
              {rating.toFixed(1)}
            </small>
          )}
          <span className="align-middle d-inline-block overallRating_starsContainer">
            <StartRating rating={rating} isTittle={!smallReviews} />
          </span>
          {!smallReviews && (
            <span className="align-middle mx-2 px-2 overallRating_ratingNumber">
              <strong>Avg. {rating}</strong>
            </span>
          )}
        </>
      )}
      {smallReviews &&
        (urlMoreReviews ? (
          <small className="text-muted ml-2 align-middle ">
            <a href={urlMoreReviews} rel="noreferrer" target="_blank">
              ({countReviews} review{countReviews > 1 ? "s" : ""})
            </a>
          </small>
        ) : (
          <small className="text-muted ml-2 align-middle">
            ({countReviews} review{countReviews > 1 ? "s" : ""})
          </small>
        ))}
      {!smallReviews && (
        <span
          className={`align-middle mx-2 px-2 text-muted ${
            showOverallRating ? "border-left" : ""
          } border-right`}
        >
          {countReviews} reviews
        </span>
      )}
      {allowWriteReviews && (
        <span className="align-middle mx-2 px-2 text-muted overallRating__writeReview">
          <button onClick={onClickWriteReviews} className="btn btn-link">
            Write a Review
          </button>
        </span>
      )}
      {urlMoreReviews && !smallReviews && (
        <small className="text-muted ml-2 align-middle overallRating__readMore">
          <a href={urlMoreReviews} rel="noreferrer" target="_blank">
            {" "}
            Read Reviews
          </a>
        </small>
      )}
      {sourceTypeName && sourceTypeName === "Yelp" && (
        <img
          className="img-fluid mx-1"
          src={"/local/images/yelplogo.png"}
          alt="Yelp Reviews Logo"
        ></img>
      )}
    </div>
  );
};

OverallRating.propTypes = {
  /** Manage visibility of overallRating control */
  showOverallRating: PropTypes.bool,
  /** Receive the global rating */
  rating: PropTypes.number,
  /** Total count of review */
  countReviews: PropTypes.number,
  /** Handles the visibility of the action to writes new reviews */
  allowWriteReviews: PropTypes.bool,
  /** Specify the function to call when the write reviews action is executed */
  onClickWriteReviews: PropTypes.func,
  /** handle the size of the rating images */
  smallReviews: PropTypes.bool.isRequired,
};

// Specifies the default values for props:
OverallRating.defaultProps = {
  smallReviews: false,
};

export default OverallRating;
