import { useEffect, useState } from "react";
import { getSearchLocation2 } from "../services/restSearchService";
import { fixRelativePath } from "../utils/bullseyeUtils";
import { getLocationSocialMedia } from "../utils/utils";

const homepage = process.env.REACT_APP_VIRTUAL_PATH;

export function useLocation(locationId, thirdPartyId, landingPageId) {
  const [locationData, setLocationData] = useState({
    loading: true,
    location: {
      SocialMedia: [],
      Id: 0,
      Name: null,
      Address1: null,
      Address2: null,
      StateAbbreviation: null,
      StateName: null,
      City: null,
      URL: null,
      CountryCode: null,
      Latitude: 0,
      Longitude: 0,
      PostCode: null,
      PhoneNumber: null,
      EmailAddress: null,
      ContactName: null,
      ContactPosition: null,
      ImageFileUrl: null,
      GooglePlaceID: null,
      SEO: {
        MetaDescription: null,
        SEOTitle: null,
      },
      CategoryTree: [
        {
          Data: null,
          ID: 0,
          Name: null,
          SubCategories: [],
          ThirdPartyID: null,
        },
      ],
      Attributes: [
        {
          AttributeDisplayText: null,
          AttributeId: 0,
          AttributeName: null,
          AttributeTypeID: null,
          AttributeValue: null,
        },
      ],
      ComplexAttributes: [
        {
          AttributeId: 0,
          AttributeName: null,
          SubAttributes: [
            {
              AttributeDisplayImage: null,
              AttributeDisplayText: null,
              AttributeId: 0,
              AttributeName: null,
              AttributeTypeID: 0,
              AttributeValue: null,
            },
          ],
        },
      ],
      DailyHoursList: [
        {
          Closed: false,
          ClosingTime: null,
          DayOrder: 0,
          HolidayHours: false,
          HolidayName: null,
          HoursDisplayText: null,
          NameOfDay: null,
          NoHoursAvailable: false,
          OpeningTime: null,
          SpecialHours: false,
        },
      ],
      LandingPageImages: [],
      Coupons: [
        {
          ClientId: 0,
          CouponId: 0,
          CouponImage: null,
          CouponText: null,
          EndDate: null,
          Name: null,
          PromoPageURL: null,
          PromotionName: null,
          StartDate: null,
          ThumbnailImage: null,
        },
      ],
      Testimonials: [],
    },
  });

  useEffect(() => {
    const getLocationAsync = async () => {
      const location = await getSearchLocation2(
        locationId,
        thirdPartyId,
        landingPageId
      );
      if (!location) {
        window.location.pathname = `${homepage}/error/not-found`;
        return null;
      }

      // Fix relative path for images
      location.ImageFileUrl = fixRelativePath(location.ImageFileUrl);
      location.LandingPageImages = location.LandingPageImages.map((photo) =>
        fixRelativePath(photo)
      );

      // Fix relative path for Coupons/Promotions
      location.Coupons = location.Coupons.map((coupon) => {
        coupon.CouponImage = fixRelativePath(coupon.CouponImage);
        coupon.ThumbnailImage = fixRelativePath(coupon.ThumbnailImage);
        return coupon;
      });

      location.SocialMedia = getLocationSocialMedia(location.SocialMedia);

      setLocationData({ loading: false, location: location });
    };

    if (locationId || thirdPartyId) {
      getLocationAsync();
    }
  }, [locationId, thirdPartyId, landingPageId]);

  return [locationData];
}
