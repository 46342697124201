import React, { useState, useEffect } from "react";
import "./googleMap.scss";
import InfoMarker from "../infoMarker";

import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";
import { useTracking } from "react-tracking";

import PropTypes from "prop-types";

/**
 * Component to draw a pin location on the map
 */
const GoogleMapMarker = ({
  text,
  pin,
  marker,
  onClick,
  showInfoWindow,
  showLandingPageLink,
  showLandingPageLinkTarget,
  showImagesInResults,
  showPopup,
  showDirectionsLink = true,
  className,
  interfaceName,
  returnUrlParameters,
  showMyFavorites,
  interfaceId,
}) => {
  const { trackEvent } = useTracking();
  const [show, setShow] = useState(showInfoWindow);

  useEffect(() => {
    setShow(showInfoWindow);
  }, [showInfoWindow]);

  return (
    <div className={className}>
      <div
        className="pin"
        onClick={() => {
          trackEvent({
            eventType: EVENT_LOG_TYPE.logSearch,
            event: "click",
            locationId: marker.id,
            responseType: SEARCH_RES_LOG_TYPE.locationMapClicked,
            locationName: marker.locationName,
            eventNameGA: "click_map_pin",
            additionalInformation: {
              location_id: marker.id,
              location_name: marker.locationName,
            },
          });
          setShow(true && showPopup);
          if (onClick) {
            onClick(marker);
          }
        }}
      >
        <img id={`pin-img-${marker.id}`} src={pin} alt="pin" />

        {text && <p>{text}</p>}
      </div>
      {show && (
        <InfoMarker
          marker={marker}
          showLandingPageLink={showLandingPageLink}
          showLandingPageLinkTarget={showLandingPageLinkTarget}
          onClose={() => setShow(false)}
          showImagesInResults={showImagesInResults}
          showDirectionsLink={showDirectionsLink}
          interfaceName={interfaceName}
          returnUrlParameters={returnUrlParameters}
          showMyFavorites={showMyFavorites}
          interfaceId={interfaceId}
        />
      )}
    </div>
  );
};

GoogleMapMarker.propTypes = {
  /** Text or label to display on the map pin */
  text: PropTypes.string,
  /**  URL image pin to display the map */
  pin: PropTypes.string.isRequired,
  /** Location Information with at least the following attributes */
  marker:
    /** Atributes per item on the location list */
    PropTypes.shape({
      /** location Id */
      id: PropTypes.number.isRequired,

      /** name of the location */
      locationName: PropTypes.string,
      /** address of the location */
      address1: PropTypes.string,
      /** city of the address */
      city: PropTypes.string,
      /** state of city */
      state: PropTypes.string,
      /** postal code of the location */
      postalCode: PropTypes.string,
      /** country of this location */
      country: PropTypes.string,
      /** url of the lading page detail of this location */
      URL: PropTypes.string,
    }).isRequired,
  /** function to on click on one item to the list will be selected into the parent */
  onClick: PropTypes.func,
  /** flag to display the InfoWindow popup or not */
  showInfoWindow: PropTypes.bool.isRequired,
  /** flag to show on the InfoWindow the name as a link or not, this will be used to redirect to a Location landing page  */
  showLandingPageLink: PropTypes.bool.isRequired,
};

// Specifies the default values for props:
GoogleMapMarker.defaultProps = {
  /** Default value to show image inside popup */
  showImagesInResults: false,
  showLandingPageLink: false,
};
export default GoogleMapMarker;
