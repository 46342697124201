import _ from "lodash";
import { InterfaceIcon } from "../interfaces";

export const bullseyeResources = process.env.REACT_APP_BULLSEYE_RESOURCE + "/";

export function getUserCountry(
  countryCode: string,
  countryName: string,
  countryId: number,
  countryArray: [{ name: string; id: number; code: string }],
  countryRegions: [{ countryId: number }]
) {
  // This will search if the countryID in the pulled from querystring or
  // the detected country in the maxmind exists in the configuration countries
  let country: number | null = null;
  let countryRegion = null;

  if (countryCode || countryName || countryId) {
    /** Search in the configuration if the user country or the country in
     * the Querystring is configured
     */
    const countryFromConfig = countryArray.find(
      (c) =>
        c.name === countryName || c.id === countryId || c.code === countryCode
    );

    if (countryFromConfig) {
      country = countryFromConfig.id;
    } else if (countryArray.length === 1) {
      country = countryArray[0].id;
    }

    countryRegion = countryRegions.find((x) => x.countryId === country);
  }

  return [country, countryRegion];
}

export function mapBullseyeDataToProps(
  object: any,
  replacements: { [name: string]: string }
) {
  let newObject = _.mapKeys(object, (value, key) => {
    const newKey = _.camelCase(key);

    return replacements[newKey] || newKey;
  });

  return newObject;
}

/** Fix bullseye resource admin Relative path to absolute path */
export function fixRelativePath(imagePath: string | null): string {
  if (!imagePath) return "";

  let returnPath = imagePath;

  if (imagePath.toLowerCase().includes("~/resources/")) {
    returnPath = imagePath
      .toLowerCase()
      .replace("~/resources/", bullseyeResources);
  }

  // I did this because sometimes the server returns encode url
  // and if I only apply the encodeURI sometimes the urls are double encode
  return encodeURI(decodeURI(returnPath));
}

export function getCustomPin(
  categoriesIcons: InterfaceIcon[],
  locationCategories: number[]
): string | null {
  let iconImage = null;

  if (categoriesIcons?.length > 0 && locationCategories?.length > 0) {
    const customIcon = categoriesIcons.find((x) =>
      x.interfaceIconCategories.some((element) =>
        locationCategories.includes(element)
      )
    );

    if (customIcon) {
      iconImage = fixRelativePath(customIcon.customMapIcon);
    }
  }

  return iconImage;
}

export function buildLandingPageUrl(
  countryCode: string | null,
  stateAbbr: string | null,
  city: string | null,
  locationName: string | null,
  locationId: number,
  interfaceName: string | null,
  baseUrl: string,
  seoProxyKeyword: string | null,
  seoLocationIdentifier: number = 1,
  thirdPartyId: string | null,
  eventPath: string | null,
  eventId: number | null,
  interfaceId: number | null
) {
  let items: string[] = [];
  let locationIdentifier: string = locationId.toString();
  let interfaceIdentifier: string | undefined;

  enum SeoIdentifier {
    LocationId = 1,
    ThirdpartyId = 2,
  }

  // We need the locationId mandatory and the interface
  if (!locationId || (!interfaceName && !interfaceId)) {
    return null;
  }

  // Remove the final slash from the base URL if present
  if (baseUrl.lastIndexOf("/") === baseUrl.length - 1) {
    baseUrl = baseUrl.slice(0, -1);
  }

  interfaceIdentifier = interfaceName ?? interfaceId?.toString();

  // We should never get here because we are validating the interfacename or id is present
  if (!interfaceIdentifier) {
    interfaceIdentifier = "";
  }

  items.push(baseUrl);
  items.push(interfaceIdentifier);

  if (eventPath && eventId) {
    items.push(eventPath);
  }

  if (seoLocationIdentifier === SeoIdentifier.ThirdpartyId && thirdPartyId) {
    locationIdentifier = thirdPartyId;
  }

  // We should not create the link if no identifier is present
  if (!locationIdentifier) return null;

  if (countryCode) items.push(countryCode);
  if (stateAbbr) items.push(stateAbbr);
  if (city) items.push(removeSpaces(city));

  /*** This part is to Build the Slug */
  const slugParameters: string[] = [];

  // We are using first the SeoKeyword if not the name
  if (seoProxyKeyword) slugParameters.push(seoProxyKeyword);
  else if (locationName) slugParameters.push(removeSpaces(locationName));

  if (city) slugParameters.push(removeSpaces(city));
  if (stateAbbr) slugParameters.push(stateAbbr);

  if (eventId) slugParameters.push(eventId.toString());
  else slugParameters.push(locationIdentifier);

  items.push(slugParameters.join("-"));
  /*** End Section This part is to Build the Slug */

  return items.join("/");
}

export function removeSpaces(word: string | null) {
  if (!word) return "";

  return word
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, "-")
    .replace(/--/g, "-");
}
