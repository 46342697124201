import { createContext } from "react";

export interface ContextProps {
  googleGATrackingCode: string | null;
  currentFavorites: Record<string, string[]>;
  localStorageAviable: boolean;

  // Functions
  setApplicationConfig: (googleGATrackingCode: string) => void;
  setCurrentFavorites: (favoritesLocations: string) => void;
  addFavoriteLocation: (
    locationId: string,
    interfaceId: number,
    thirdPartyId: string
  ) => void;
  removeFavoriteLocation: (
    LocationId: string,
    interfaceId: number,
    thirdPartyId: string
  ) => void;
}

export const ApplicationContext = createContext({} as ContextProps);
