import http from "./httpService";

const endpointPath = "RestLead.svc";

const getLeadFormConfigurationionGetMethod = "GetLeadFormConfiguration";
const saveLeadFormConfigurationionPutMethod = "AddLead";
const getLeadSourcesGetMethod = "GetLeadSources";

function getLeadSourcesURL() {
  return `${endpointPath}/${getLeadSourcesGetMethod}`;
}
function getLeadFormConfigurationURL(formID) {
  return `${endpointPath}/${getLeadFormConfigurationionGetMethod}?formID=${formID}`;
}
function saveLeadFormConfigurationURL() {
  return `${endpointPath}/${saveLeadFormConfigurationionPutMethod}`;
}
export async function getLeadFormConfiguration(formId) {
  http.setSecurityParameters();
  const config = await http.get(getLeadFormConfigurationURL(formId));
  return config.data;
}
export async function saveLeadFormConfiguration(formData) {
  const config = await http.post(saveLeadFormConfigurationURL(), formData, {
    params: null,
  });
  return config ? true : false;
}
export async function getLeadSources() {
  http.setSecurityParameters();
  const config = await http.get(getLeadSourcesURL());
  return config.data;
}
