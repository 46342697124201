import React, { useRef, useContext, useState, useEffect } from "react";
import { useTracking } from "react-tracking";
import { Link } from "react-router-dom";
import "./infoMarker.scss";
import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";
import { buildGetDirectionsLink } from "../../utils/bullseyeUtils";

import PropTypes from "prop-types";
import { LanguageContext } from "../../context/language";

import { HeartFill, Heart } from "react-bootstrap-icons";
import ToggleIcon from "../ToggleIcon/ToggleIcon";
import { ApplicationContext } from "../../context/application";
const InfoMarker = ({
  marker,
  onClose,
  showLandingPageLink,
  showLandingPageLinkTarget,
  containerCss,
  useExternalLandingPageLink,
  containerStyles,
  showDirectionsLink,
  showImagesInResults,
  interfaceName,
  returnUrlParameters,
  showMyFavorites,
  interfaceId,
}) => {
  const {
    locationName,
    address1,
    city,
    stateAbbr,
    state,
    postalCode,
    country,
    imageFileUrl,
    id,
    landingPageUrl,
    promotions,
    contactName,
    contactPosition,
    contactUrl,
    emailAddress,
    phone,
    mobileNumber,
    faxNumber,
    url,
    thirdPartyId,
  } = marker;

  // This is only temporary we need to change all references to use
  // promotions always but for now we are doing this logic
  let couponInformation = null;

  if (promotions && promotions.length > 0) {
    const defaultUrl = promotions[0].url
      ? promotions[0].url
      : `/pages/coupons/${interfaceName}?locid=${id}`;

    couponInformation = {
      image: promotions[0].image,
      body: promotions[0].body,
      url: defaultUrl,
    };
  }

  let URL = marker.URL;

  if (!URL && landingPageUrl) {
    URL = landingPageUrl + (returnUrlParameters ? returnUrlParameters : "");
  }

  let fullAddress = "";
  if (city) fullAddress = city;
  if (fullAddress && (state || stateAbbr || postalCode)) fullAddress += ",";
  if (state) fullAddress += ` ${state}`;
  if (stateAbbr) fullAddress += ` ${stateAbbr}`;
  if (postalCode) fullAddress += ` ${postalCode}`;

  const { trackEvent } = useTracking({
    locationName: locationName,
    locationId: id,
  });
  const infoWindowContainer = useRef();

  const { getClientTerm } = useContext(LanguageContext);
  const {
    currentFavorites,
    removeFavoriteLocation,
    addFavoriteLocation,
    localStorageAviable,
  } = useContext(ApplicationContext);

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (currentFavorites) {
      setIsFavorite(currentFavorites[interfaceId]?.includes(`${id}`));
    }
  }, [currentFavorites]);

  return (
    <div
      ref={infoWindowContainer}
      className={`rounded-1 ${containerCss} infoMarker__container`}
      style={{
        ...containerStyles,
        bottom: (infoWindowContainer.current?.clientHeight || 0) + 45,
      }}
    >
      {onClose && (
        <div className="text-right bg-white p-0">
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
      <div className="p-1">
        <div className="gm-style gm-style-iw">
          <div className="d-flex">
            {(couponInformation?.image || imageFileUrl) && showImagesInResults && (
              <div className="mr-3 pt-1">
                <img
                  src={
                    couponInformation?.image
                      ? couponInformation?.image
                      : imageFileUrl
                  }
                  alt={"Result Image " + locationName}
                ></img>
              </div>
            )}

            <div className="flex-fill">
              <div>
                <ul className="list-unstyled mb-0">
                  <li>
                    <h6>
                      {showLandingPageLink && URL ? (
                        useExternalLandingPageLink ? (
                          <a
                            href={URL}
                            target={showLandingPageLinkTarget}
                            onClickCapture={() =>
                              trackEvent({
                                eventType: EVENT_LOG_TYPE.logSearch,
                                event: "click",
                                eventNameGA: "more_info_click",
                                responseType:
                                  SEARCH_RES_LOG_TYPE.moreInfoClicked,
                              })
                            }
                          >
                            {locationName}
                          </a>
                        ) : (
                          <Link
                            to={URL}
                            target={showLandingPageLinkTarget}
                            onClickCapture={() =>
                              trackEvent({
                                eventType: EVENT_LOG_TYPE.logSearch,
                                event: "click",
                                eventNameGA: "more_info_click",
                                responseType:
                                  SEARCH_RES_LOG_TYPE.moreInfoClicked,
                              })
                            }
                          >
                            {locationName}
                          </Link>
                        )
                      ) : (
                        locationName
                      )}
                    </h6>
                  </li>
                  {address1 && <li>{address1}</li>}
                  {fullAddress && <li>{fullAddress}</li>}
                  <li>
                    <div className="infomarker_extrainformation">
                      <input
                        type={"hidden"}
                        value={JSON.stringify({
                          contactName: contactName,
                          contactPosition: contactPosition,
                          contactUrl: contactUrl,
                          emailAddress: emailAddress,
                          phone: phone,
                          mobileNumber: mobileNumber,
                          faxNumber: faxNumber,
                          url: url,
                          locationId: id,
                          categoryIds: marker.categoryIds,
                        })}
                      ></input>
                    </div>
                  </li>
                  {faxNumber && (
                    <li className="map-faxNumber" hidden>
                      {faxNumber}
                    </li>
                  )}
                  <li>
                    {(showDirectionsLink || couponInformation) && (
                      <hr className="my-2" />
                    )}
                    {showDirectionsLink && (
                      <a
                        itemProp="map"
                        onClickCapture={() =>
                          trackEvent({
                            eventType: EVENT_LOG_TYPE.logSearch,
                            event: "click",
                            responseType: SEARCH_RES_LOG_TYPE.directionsClicked,
                            eventNameGA: "directions_click",
                            extraParameters: {
                              url_link: buildGetDirectionsLink(
                                address1,
                                city,
                                stateAbbr,
                                postalCode,
                                country
                              ),
                            },
                          })
                        }
                        target="_blank"
                        rel="noreferrer"
                        href={buildGetDirectionsLink(
                          address1,
                          city,
                          stateAbbr,
                          postalCode,
                          country
                        )}
                      >
                        {getClientTerm("defaultDirectionsTerm", "Directions") +
                          " "}
                      </a>
                    )}
                    {couponInformation && showDirectionsLink && (
                      <span className="bullseye-red"> | </span>
                    )}
                    {couponInformation && (
                      <a
                        href={couponInformation.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {couponInformation.body}
                      </a>
                    )}
                    {showMyFavorites && localStorageAviable && (
                      <span className="float-right">
                        <ToggleIcon
                          ActiveIcon={<HeartFill width={17} height={17} />}
                          InactiveIcon={<Heart width={17} height={17} />}
                          defaultActiveState={isFavorite}
                          forceState={isFavorite}
                          onToggle={(active) => {
                            trackEvent({
                              eventNameGA: active
                                ? "click_favorite"
                                : "click_unfavorite",
                            });

                            if (active) {
                              addFavoriteLocation(
                                `${id}`,
                                interfaceId,
                                thirdPartyId
                              );
                            } else {
                              removeFavoriteLocation(
                                `${id}`,
                                interfaceId,
                                thirdPartyId
                              );
                            }
                          }}
                        />
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="arrow" />
    </div>
  );
};

InfoMarker.propTypes = {
  /** Location Information with at least the following attributes */
  marker:
    /** Atributes per item on the location list */
    PropTypes.shape({
      /** name of the location */
      locationName: PropTypes.string.isRequired,
      /** address of the location */
      address1: PropTypes.string,
      /** city of the address */
      city: PropTypes.string,
      /** state of city */
      state: PropTypes.string,
      /** postal code of the location */
      postalCode: PropTypes.string,
      /** country of this location */
      country: PropTypes.string,
      /** url of the lading page detail of this location */
      URL: PropTypes.string,
    }).isRequired,

  /** flag to show on the InfoWindow the name as a link or not, this will be used to redirect to a Location landing page  */
  showLandingPageLink: PropTypes.bool.isRequired,
  containerCss: PropTypes.string.isRequired,
  containerStyles: PropTypes.shape().isRequired,
  showDirectionsLink: PropTypes.bool.isRequired,
  useExternalLandingPageLink: PropTypes.bool.isRequired,
  /** function to on click on one item to the list will be selected into the parent */
  onClose: PropTypes.func,
};

// Specifies the default values for props:
InfoMarker.defaultProps = {
  containerCss: "infoWindowPosition infoWindow",
  containerStyles: {},
  showDirectionsLink: true,
  useExternalLandingPageLink: false,
  showImagesInResults: false,
};

export default InfoMarker;
