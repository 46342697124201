import { createContext } from "react";
import { SearchParameters } from "../../interfaces";

export interface ContextProps {
  isLoading: boolean;
  searchParameters: SearchParameters;

  doSearch: () => void;
  setParameters: (parameters: SearchParameters) => void;
}

export const SearchContext2 = createContext({} as ContextProps);
