import { createContext } from "react";
import { DoSearch2Response, Location } from "../../interfaces";

export interface ContextProps {
  distanceIsMetric: boolean;
  findNearestSearch: boolean;
  locations: Location[];
  mapLocations: Location[];
  searchReqLogId: number;
  totalResults: number;

  setLocations: (doSearch2Results: DoSearch2Response) => void;
  setMapLocations: (doSearch2Results: DoSearch2Response) => void;
  clearLocations: () => void;
  buildLandingPages: (
    interfaceName: string | null,
    baseUrl: string,
    proxyKeyword: string | null,
    locationIdentifier: number,
    interfaceId: number | null,
    eventId: number | null,
    eventPath: string | null
  ) => void;
}

export const LocationsContext = createContext({} as ContextProps);
