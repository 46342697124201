import { useEffect, useState } from "react";
import { getInterfaceConfig } from "../services/restInterfaceConfiguration";
import { fixRelativePath } from "../utils/bullseyeUtils";
import { GEOCODING_SERVICE } from "../utils/constants";

const homepage = process.env.REACT_APP_VIRTUAL_PATH;

export function useClientConfiguration(
  interfaceName,
  apiKeyName,
  language,
  interfaceId
) {
  const [locatorConfiguration, setLocatorConfiguration] = useState({
    clientId: -1,
    apiKey: null,
    containerWidth: null,
    countries: [],
    interfaceConfigCategories: [],
    interfaceSLFormRadiusOptions: [],
    configClientSearch: {},
    selectedRadius: null,
    filteredCategories: null,
    useIPDetection: false,
    loading: true,
    categoryGroups: [],
    CityPageCollectionID: -1,
  });

  /** get Icon to the Map */
  useEffect(() => {
    /** get the configuration for the locator */
    const getLocatorConfigurationAsync = async () => {
      const config = await getInterfaceConfig(
        interfaceName,
        apiKeyName,
        language,
        interfaceId
      );

      if (!config) {
        window.location.pathname = `${homepage}/error/not-configured`;
        return;
      }

      config.interfaceConfigCategories = config.interfaceConfigCategories ?? [];
      config.loading = false;

      // Format ClientId and ApiKey
      config.configClientSearch = {
        clientId: parseInt(config.clientId),
        apiKey: config.apiKey,
      };

      // Join into a single string the filtered categories
      config.filteredCategories = config.interfaceConfigCategories
        .map((cat) => cat.categoryId)
        .join(",");

      // The selected radius from the interface
      config.selectedRadius = config.interfaceSLFormRadiusOptions.find(
        (x) => x.defaultOption
      )?.radius;

      config.radiusOptions = config.interfaceSLFormRadiusOptions.map(
        (radius) => {
          return {
            id: radius.radius,
            name: radius.radius,
          };
        }
      );

      if (config.enableTranslation && config.allLanguages?.length > 0) {
        config.allLanguages = config.allLanguages.map((lang) => {
          return {
            id: lang.languageCode,
            name: lang.languageName,
          };
        });
      }

      // Get the default pin map, we get the first pin without categories
      config.defaultMapIcon = fixRelativePath(
        config.interfaceIcons?.filter(
          (x) => x.interfaceIconCategories.length === 0
        )[0]?.customMapIcon
      );

      if (config.faviconFileUrl) {
        config.faviconFileUrl = fixRelativePath(config.faviconFileUrl);
      }

      config.categoryGroups = config.interfaceCategoryGroups
        ? config.interfaceCategoryGroups.map((cat) => {
            return {
              id: cat.categoryID,
              required: cat.filterRequired,
              categoryMode: cat.interfaceCategoryFilterStyleOptionID,
              name: cat.name,
              categories: config.interfaceCategoryFilterOptions
                .filter((subcat) => subcat.parentCategoryId === cat.categoryID)
                .map((subcat) => {
                  return {
                    id: subcat.categoryId,
                    name: subcat.categoryName,
                    imageFileURL: subcat.imageFileURL,
                    subCategories: config.interfaceSubcategoryFilterOptions
                      .filter(
                        (subcat2) =>
                          subcat2.parentCategoryId === subcat.categoryId
                      )
                      .map((subcat2) => {
                        return {
                          id: subcat2.categoryId,
                          name: subcat2.categoryName,
                          imageFileURL: subcat2.imageFileURL,
                        };
                      }),
                  };
                }),
              showSubcategory: cat.showSubcategory,
              subcategoryRequired: cat.subcategoryRequired,
            };
          })
        : [];

      // Flag to show GoogleMap
      config.showGoogleMap =
        config?.mappingSettings[0]?.geocodingServiceID ===
          GEOCODING_SERVICE.BEGoogle ||
        config?.mappingSettings[0]?.geocodingServiceID ===
          GEOCODING_SERVICE.Google;

      // Flag to show Mapbox
      config.showMapbox =
        config?.mappingSettings[0]?.geocodingServiceID ===
          GEOCODING_SERVICE.BEMapbox ||
        config?.mappingSettings[0]?.geocodingServiceID ===
          GEOCODING_SERVICE.Mapbox;

      // This is for test with one country only
      // config.countries.length = 1;

      setLocatorConfiguration(config);
    };

    getLocatorConfigurationAsync();
  }, [interfaceName, apiKeyName, language, interfaceId]);

  return locatorConfiguration;
}
