import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./testimonialCarousel.scss";

/** Review list component, display a cards list with the comments and reviews made it by users  */
const TestimonialsCarousel = ({ title, testimonials }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2048 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 2048, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      {title && <div className="bullseye-red h4">{title}</div>}
      {testimonials && testimonials.length > 0 && (
        <Carousel
          responsive={responsive}
          containerClass="carousel-container"
          itemClass="carousel-item"
        >
          {testimonials.map((item) => {
            return (
              <div
                key={item.id}
                className="d-flex flex-column flex-md-row px-5"
              >
                {item.image && (
                  <div className="text-center mt-3 px-3 py-5 order-1 order-md-1">
                    <img
                      src={item.image}
                      alt={"Profile"}
                      className="rounded-circle"
                      style={{ width: "100px", height: "100px", border: 0 }}
                    />
                  </div>
                )}
                <div className="order-1 order-md-2 m-4">
                  <blockquote key={item.id} className="blockquote__salesx my-3">
                    <p className="mb-0">{item.description}</p>
                    {item.author && (
                      <footer className="blockquote-footer text-right mt-2">
                        {item.author}
                      </footer>
                    )}
                  </blockquote>
                </div>
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
TestimonialsCarousel.propTypes = {
  /** Text label to show at the card */
  title: PropTypes.string,
  /** List of testimony to display */
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      /** id of the testimony */
      id: PropTypes.string,
      /** Testimonial description */
      description: PropTypes.string.isRequired,
      /** Author of the testimony*/
      author: PropTypes.string,

      image: PropTypes.string,
    })
  ).isRequired,
};
export default TestimonialsCarousel;
