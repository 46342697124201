import React, { useState } from "react";

export const SearchContext = React.createContext();

export const SearchProvider = ({ children }) => {
  const [searchParameters, setSearchParameters] = useState({
    postalCode: null,
    countryId: null,
    radius: 0,
    latitude: null,
    longitude: null,
    city: null,
    state: null,
    loading: true,
    ready: false,
    categoryIDs: null,
    searchlogid: null,
    userIPAddress: null,
  });

  const [searchDisplayText, setSearchDisplayText] = useState({
    cityOrPostalCode: "",
    country: "",
  });

  const [currentLocation, setCurrentLocation] = useState({
    origin_formatted_address: null,
    latitude: 0,
    longitude: 0,
    formatted_address: null,
    country: null,
    userIPAddress: null,
    geocode_success: false,
    loading: true,
  });

  return (
    <SearchContext.Provider
      value={{
        searchParameters,
        setSearchParameters,
        searchDisplayText,
        setSearchDisplayText,
        currentLocation,
        setCurrentLocation,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
