import { FC, PropsWithChildren, useReducer } from "react";
import { LanguageContext, languageReducer } from "./";

export interface LanguageState {
  clientTerms: { [key: string]: string };
  language: string;
}

const Language_INITIAL_STATE: LanguageState = {
  clientTerms: {},
  language: "en",
};

export const LanguageProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(languageReducer, Language_INITIAL_STATE);

  const setClientTerms = (
    language: string,
    clientTerms: { [key: string]: string }
  ) => {
    dispatch({
      type: "[Language] - Set ClientTerms",
      payload: { clientTerms, language },
    });
  };

  const getClientTerm = (key: string, defaultValue: string) => {
    if (state.clientTerms.hasOwnProperty(key)) {
      return state.clientTerms[key];
    }

    return defaultValue;
  };

  return (
    <LanguageContext.Provider
      value={{ ...state, setClientTerms, getClientTerm }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
