import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

import Label from "../label";
import ErrorDisplay from "../errorDisplay";

import "./categoriesTreeInput.scss";
const CategoriesTreeInput = ({
  label,
  name,
  options,
  required = false,
  register,
  compact = false,
  errors,
  onChange,
}) => {
  const [valueSelected, setValueSelected] = useState("");

  const DropDownTreeSelectM = useMemo(() => {
    return (
      <DropdownTreeSelect
        data={options}
        onChange={(currentNode, selectedNodes) => {
          setValueSelected(selectedNodes.map((x) => x.value).join(","));
        }}
        onAction={(node, action) => {
          //console.log("onAction::", action, node);
        }}
        onNodeToggle={(currentNode) => {
          //console.log("onNodeToggle::", currentNode);
        }}
        className="categoryTreeInput"
      />
    );
  }, [options]);

  return (
    <div className="form-group categoryTreeInput__container">
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      {DropDownTreeSelectM}

      <input
        id={name}
        type={"hidden"}
        value={valueSelected}
        name={name}
        ref={register}
        onChange={onChange}
      />
      {errors[name] && <ErrorDisplay errorMessage={errors[name]?.message} />}
    </div>
  );
};
CategoriesTreeInput.propTypes = {
  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,

  /** text to put into the input like placeholder */
  placeholder: PropTypes.string,

  /** Parameter  */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,
  /** default value */
  value: PropTypes.string,

  props: PropTypes.object,
};
export default CategoriesTreeInput;
