import React, { useState } from "react";
import Label from "../label";
import "./rateServiceInput.scss";
import PropTypes from "prop-types";
import ErrorDisplay from "../errorDisplay/";

const RateServiceInput = ({
  name,
  label,
  small = false,
  compact = false,
  value,
  register,
  errors,
  required = false,
  onChange,
  ...props
}) => {
  const [valueRating, setValueRating] = useState(!value ? 0 : value);
  const [evalRating, setEvalRating] = useState(0);
  const ratingChanged = (newRating) => {
    setValueRating(newRating);
    setEvalRating(newRating);

    var input = document.getElementById(name);
    var event = new Event("input", { bubbles: true });
    event.simulated = true;
    input.value = newRating;
    input.dispatchEvent(event);
  };

  const DrawBars = () => {
    let rows = [];
    for (let i = 1; i < 6; i++) {
      rows.push(
        <div
          key={i}
          className={`progressBar ${evalRating >= i ? "fill" : ""}`}
          onClick={() => {
            ratingChanged(i);
          }}
          onMouseEnter={() => {
            if (i > valueRating) {
              setEvalRating(i);
            }
          }}
          onMouseOut={() => {
            setEvalRating(valueRating);
          }}
        ></div>
      );
    }
    return rows;
  };

  return (
    <div className="form-group">
      <Label htmlFor={name} label={label} required={required} />
      <div className="d-flex flex-column mb-3">
        <div className="d-flex">
          <DrawBars />
        </div>
      </div>
      <input
        id={name}
        name={name}
        type={"text"}
        value={value}
        ref={register}
        onChange={onChange}
        {...props}
        className={"d-none"}
      />
      {errors[name] && <ErrorDisplay errorMessage={errors[name]?.message} />}
    </div>
  );
};

RateServiceInput.propTypes = {
  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,
  /** flag is requiered the input */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,
  /** default value */
  value: PropTypes.string,
  /** function whe the value change*/
  onChange: PropTypes.func.isRequired,
  /** child component to display next to the input */
  children: PropTypes.node,

  props: PropTypes.object,
};

export default RateServiceInput;
