import { FC, PropsWithChildren, useReducer, useEffect } from "react";
import { ApplicationContext, applicationReducer } from "./";

export interface ApplicationState {
  googleGATrackingCode: string | null;
  currentFavorites: Record<string, string[]>;
  localStorageAviable: boolean;
}

const Application_INITIAL_STATE: ApplicationState = {
  googleGATrackingCode: null,
  currentFavorites: {},
  localStorageAviable: false,
};

export const ApplicationProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    applicationReducer,
    Application_INITIAL_STATE
  );

  useEffect(() => {
    let localStorageAvailable = true;

    try {
      window.localStorage.setItem("localStorage", "local");
    } catch (error) {
      localStorageAvailable = false;
    }

    dispatch({
      type: "[application] - localStorageAvailable",
      payload: localStorageAvailable,
    });

    if (localStorageAvailable) {
      dispatch({
        type: "[application] - SetFavorites",
        locations: localStorage.getItem("locationsFavorites")
          ? JSON.parse(localStorage.getItem("locationsFavorites") || "")
          : "[]",
      });
    }
  }, []);

  const addFavoriteLocation = (
    locationId: string,
    interfaceId: number,
    thirdPartyId: string
  ) => {
    const postMessageFavorite = {
      type: "favorite",
      payload: {
        locationId: `${locationId}`,
        interfaceId: `${interfaceId}`,
        thirdPartyId: `${thirdPartyId}`,
      },
    };

    window.parent.postMessage(postMessageFavorite, "*");
    const payload = {
      interfaceId: `${interfaceId}`,
      locationId: `${locationId}`,
    };

    dispatch({
      type: "[application] - addFavoriteLocation",
      payload: JSON.stringify(payload),
    });
  };

  const removeFavoriteLocation = (
    locationId: string,
    interfaceId: number,
    thirdPartyId: string
  ) => {
    const postMessageFavorite = {
      type: "un-favorite",
      payload: {
        locationId: `${locationId}`,
        interfaceId: `${interfaceId}`,
        thirdPartyId: `${thirdPartyId}`,
      },
    };

    window.parent.postMessage(postMessageFavorite, "*");

    const payload = {
      interfaceId: `${interfaceId}`,
      locationId: `${locationId}`,
    };

    dispatch({
      type: "[application] - removeFavoriteLocation",
      payload: JSON.stringify(payload),
    });
  };

  const setCurrentFavorites = (favoritesLocations: string) => {
    dispatch({
      type: "[application] - SetFavorites",
      payload: favoritesLocations,
    });
  };

  const setApplicationConfig = (googleGATrackingCode: string) => {
    dispatch({
      type: "[application] - SetConfiguration",
      payload: googleGATrackingCode,
    });
  };

  return (
    <ApplicationContext.Provider
      value={{
        ...state,
        setApplicationConfig,
        setCurrentFavorites,
        addFavoriteLocation,
        removeFavoriteLocation,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
