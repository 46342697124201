import GetEventInformation from "./components/eventLandingPage/getEventInformation";
import ErrorPage from "./components/pages/errors/errorPage";
// import GetParameterPage from "./components/pages/getParameterPage";
// import GetMapListConfiguration from "./components/pages/listMapLocator/getMapListConfiguration";
// import ListOnly from "./components/pages/listOnly";
// import TestInterfaceIframe from "./components/pages/testInterfaceIframe";
import SalesXPage from "./components/pages/salesX/SalesXPage";

const homepage = process.env.REACT_APP_VIRTUAL_PATH;
const bullseyeCommonURLStructure =
  "/:country([A-Za-z]{2,3})?/:state([A-Za-z0-9]{1,4})?/:city([A-Za-z0-9-]+)?";
const bullseyeCleanCommonURLStructure = "/:country/:state/:city";

export const routes = [
  {
    path: `${homepage}error/:errorType`,
    name: "Error Page",
    Component: ErrorPage,
    cleanPath: `${homepage}error/:errorType`,
  },
  {
    path: `${homepage}salesxceleration/:country/:state/:slug-:location(\\w+[\\$d\\$w+]*)`,
    name: "SalesX Demo Page",
    Component: SalesXPage,
    cleanPath: `${homepage}salesxceleration/:country/:state/:slug-:location`,
  },
  {
    path: `${homepage}salesxceleration/(.*)/:location(\\w+[\\$d\\$w+]*)`,
    name: "SalesX Demo Page",
    Component: SalesXPage,
    cleanPath: `${homepage}salesxceleration/:location`,
  },
  {
    path: `${homepage}:interfaceName/events${bullseyeCommonURLStructure}/:slug-:eventId(\\w+[\\$d\\$w+]*)`,
    name: "Event Landing Page",
    Component: GetEventInformation,
    canonical: ":interfaceName/events",
    cleanPath: `${homepage}:interfaceName/events${bullseyeCleanCommonURLStructure}/:slug-:eventId`,
  },
  {
    path: `${homepage}:interfaceName${bullseyeCommonURLStructure}/:slug1-:location(\\w+[\\$d\\$w+]*)/events/:slug2-:eventId(\\w+[\\$d\\$w+]*)`,
    name: "Location Event",
    Component: GetEventInformation,
    canonical: `${bullseyeCommonURLStructure}/:slug1-:location(\\w+[\\$d\\$w+]*)/events/:slug2-:eventId`,
    cleanPath: `${homepage}:interfaceName${bullseyeCleanCommonURLStructure}/:slug1-:location/events/:slug2-:eventId`,
  },
  {
    path: `${homepage}`,
    name: "Not Found Error",
    Component: ErrorPage,
    cleanPath: `${homepage}`,
  },
];
