import { FC, PropsWithChildren, useReducer } from "react";
import { DoSearch2Response, Location } from "../../interfaces";
import {
  buildLandingPageUrl,
  fixRelativePath,
  mapBullseyeDataToProps,
} from "../../utils";
import { mapBullseyeHoursToProps } from "../../utils/bullseyeUtils";
import { LocationsContext, locationsReducer } from "./";

export interface LocationsState {
  distanceIsMetric: boolean;
  findNearestSearch: boolean;
  locations: Location[];
  mapLocations: Location[];
  searchReqLogId: number;
  totalResults: number;
}

const Locations_INITIAL_STATE: LocationsState = {
  distanceIsMetric: false,
  findNearestSearch: false,
  locations: [],
  mapLocations: [],
  searchReqLogId: 0,
  totalResults: 0,
};

export const LocationsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(
    locationsReducer,
    Locations_INITIAL_STATE
  );

  const clearLocations = () => {
    dispatch({
      type: "[Locations] - ClearLocations",
      payload: Locations_INITIAL_STATE,
    });
  };

  const setLocations = (doSearch2Results: DoSearch2Response) => {
    const replacements = {
      countryCode: "country",
      stateAbbreviation: "stateAbbr",
      phoneNumber: "phone",
      name: "locationName",
      postCode: "postalCode",
    };

    const results = doSearch2Results.ResultList.map((location) => {
      let customLocation = mapBullseyeDataToProps(
        { ...location },
        replacements
      ) as Location;

      // Convert Relative ~/Resource/[ClientId]/ bullseye path to abosolute path
      customLocation.imageFileUrl = fixRelativePath(location.ImageFileUrl);
      customLocation.locationTypeIconUrl = fixRelativePath(
        location.LocationTypeIconURL
      );

      // Convert simple day array to a collection of React Elements
      if (location.DailyHoursList) {
        customLocation.daysArray = mapBullseyeHoursToProps(
          location.DailyHoursList
        );
      }

      /** Transform coupon to more friendly names */
      if (location.Coupons && location.Coupons.length > 0) {
        customLocation.promotions = location.Coupons.map((coupon) => {
          return {
            id: coupon.CouponId,
            body: coupon.CouponText,
            url: coupon.PromoPageURL,
            image: fixRelativePath(coupon.ThumbnailImage),
            promotionName: coupon.PromotionName,
          };
        });
      }

      customLocation.landingPageEventUrl = "";
      customLocation.landingPageUrl = "";

      return customLocation;
    });

    const payload: LocationsState = {
      distanceIsMetric: doSearch2Results.DistanceIsMetric,
      findNearestSearch: doSearch2Results.FindNearestSearch,
      locations: results,
      mapLocations: [...state.mapLocations],
      searchReqLogId: doSearch2Results.SearchReqLogId,
      totalResults: doSearch2Results.TotalResults,
    };

    dispatch({ type: "[Locations] - SetLocations", payload: payload });
  };

  const setMapLocations = (doSearch2Results: DoSearch2Response) => {
    const replacements = {
      countryCode: "country",
      stateAbbreviation: "stateAbbr",
      phoneNumber: "phone",
      name: "locationName",
      postCode: "postalCode",
    };

    const results = doSearch2Results.ResultList.map((location) => {
      let customLocation = mapBullseyeDataToProps(
        { ...location },
        replacements
      ) as Location;

      return customLocation;
    });

    dispatch({ type: "[Locations] - SetMapLocations", payload: results });
  };

  const buildLandingPages = (
    interfaceName: string | null,
    baseUrl: string,
    proxyKeyword: string | null,
    locationIdentifier: number,
    interfaceId: number | null,
    eventId: number | null,
    eventPath: string | null
  ) => {
    const payload = state.locations.map((location) => {
      const landingPageUrl = buildLandingPageUrl(
        location.country,
        location.state,
        location.city,
        location.locationName,
        location.id,
        interfaceName,
        baseUrl,
        proxyKeyword,
        locationIdentifier,
        location.thirdPartyId,
        null,
        null,
        interfaceId
      );

      if (eventId && eventPath) {
      }

      return { ...location, landingPageUrl };
    });

    dispatch({ type: "[Locations] - BuildLandingPages", payload: payload });
  };

  return (
    <LocationsContext.Provider
      value={{
        ...state,
        setLocations,
        setMapLocations,
        clearLocations,
        buildLandingPages,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
