import React from "react";
import PropTypes from "prop-types";
import "./showcase.scss";

/**
 * Showcase Card with an icon on the top, a title below and paragraph
 */
const ShowcaseCard = ({
  icon,
  title,
  body,
  links,
  itemCssClass,
  iconSize,
  cssColor,
}) => {
  const iconIsSpriteSvg = icon && icon.includes(".svg#");

  return (
    <div className="col-lg-4 pl-5">
      <div className={`m-1 mr-5 ${itemCssClass || ""}`}>
        {!iconIsSpriteSvg && (
          <img
            className="m-0 icon"
            src={icon}
            style={
              iconSize
                ? {
                    width: iconSize?.x,
                    height: iconSize?.y,
                  }
                : {}
            }
            alt={title || "showcase"}
          />
        )}
        {iconIsSpriteSvg && (
          <svg
            role="img"
            style={{ fill: cssColor }}
            className="my-3 d-block nohover showcase__widthSquare50"
          >
            <use href={icon} />
          </svg>
        )}

        <div className="ml-4">
          <strong>{title}</strong>
          <p>{body}</p>
          <ul className="list-unstyled showcaseCard__list">
            {links &&
              links.length > 0 &&
              links.map((link) => {
                if (link && link?.url !== "#") {
                  return (
                    <li key={link.url}>
                      <a href={link.url} style={{ color: cssColor }}>
                        {link.title}
                      </a>
                    </li>
                  );
                }

                return (
                  <li key={link.title} style={{ color: cssColor }}>
                    {link.title}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

ShowcaseCard.propTypes = {
  /** Icon URL to show on top */
  icon: PropTypes.string,
  /** Main card title */
  title: PropTypes.string,
  /** Description in a paragraph */
  body: PropTypes.string,
  /** Links to display in the bottom of the Card */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      /** URL (Open in the same tab) */
      url: PropTypes.string.isRequired,
      /** Display text for the Link */
      title: PropTypes.string.isRequired,
    })
  ),

  /**Change the color of event depend of the theme */
  cssColor: PropTypes.string,
};

export default ShowcaseCard;
