import React from "react";
import PropTypes from "prop-types";
import Label from "../label/label";
import ErrorDisplay from "../errorDisplay/errorDisplay";
const CommentInput = ({
  name,
  label,
  small = false,
  compact = false,
  register,
  errors,
  type = "text",
  placeholder = "",
  required = false,
  onChange,
  ...props
}) => {
  return (
    <div className="form-group" id={`attr_container_${name}`}>
      {!compact && <Label htmlFor={name} label={label} required={required} />}

      <textarea
        id={name}
        className={`form-control ${small ? "form-control-sm" : ""} ${
          errors[name] ? "is-invalid" : ""
        } `}
        name={name}
        placeholder={placeholder ? placeholder : compact ? label : ""}
        ref={register}
        onChange={onChange}
        {...props}
        aria-required={required || null}
      />
      {errors["endDate"] && (
        <ErrorDisplay
          errorMessage={errors["endDate"]?.message}
          isAllCols={true}
        />
      )}
    </div>
  );
};

CommentInput.propTypes = {
  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,

  /** text to put into the input like placeholder */
  placeholder: PropTypes.string,

  /** Parameter  */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,
  /** default value */
  value: PropTypes.string,

  props: PropTypes.object,
};

export default CommentInput;
