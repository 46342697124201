import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Address from "../address";
import LabelWithIcon from "../labelWithIcon/labelWithIcon";
import OverallRating from "../reviews/overallRating";
import GoogleReviews from "../googleReviews";
import YelpReviews from "../yelpReviews";
import LinkButton from "../linkButton/linkButton";

import { useTracking } from "react-tracking";
import { EVENT_LOG_TYPE, SEARCH_RES_LOG_TYPE } from "../../utils/constants";
import CarRed from "../../assets/images/directions_car_filled_red.png";
import "./locationInformation.scss";
import { isMobile } from "react-device-detect";
import { buildGetDirectionsLink } from "../../utils/bullseyeUtils";
import { LanguageContext } from "../../context/language";

/** Icons */
import {
  TelephoneOutbound,
  Globe2,
  Signpost2,
  Envelope,
  GeoAltFill,
} from "react-bootstrap-icons";

const LocationInformation = ({
  address1,
  address2,
  address3,
  address4,
  city,
  stateAbbr,
  postalCode,
  country,
  locationName,
  contactName,
  contactPosition,
  landingPageURL,
  showLandingPageLinkTarget,
  distance = -1,
  drivingDistanceUrl,
  drivingDistance,
  phone,
  showViewPhoneNumber,
  url,
  emailAddress,
  internetLocationText,
  distanceUnit = "km",
  imageFileUrl,
  showReviews,
  overallReviewsRating,
  countTotalReviews,
  reviewType,
  googlePlaceId,
  yelpPlaceId,
  googleMapIsReady,
  locationId,
  defaultMapIcon,

  showAddress = true,
  showAdministrativeArea = false,

  claimThisListingUrl = null,
  locationTypeIconUrl,
  locationTypeName,
  faxNumber,
  isFavoriteSelected,
}) => {
  const InternetLocationIcon = (
    <svg
      role="img"
      className="mr-1"
      width={15}
      height={15}
      style={{ color: "#bbb", marginTop: "-0.1rem " }}
    >
      <use xlinkHref={`/local/images/b-icons/bootstrap-icons.svg#globe`} />
    </svg>
  );

  const { getClientTerm, language } = useContext(LanguageContext);

  const { Track, trackEvent } = useTracking({
    locationName: locationName,
    locationId: locationId,
  });

  let formattedDistance = distance;

  if (distance > 0 && language) {
    formattedDistance = parseFloat(distance).toLocaleString(language);
  }

  const [isShowViewPhoneNumber, setIsShowViewPhoneNumber] =
    useState(showViewPhoneNumber);

  const iconPositionDefault = "top";

  if (country === "US" || country === "GB") {
    distanceUnit = "mi";
  }

  return (
    <>
      <Track>
        <div
          className={`d-flex locationInformation__container flex-column flex-md-row`}
        >
          {imageFileUrl && (
            <div className={`mr-3 pt-4 mt-2 locationInformation__imgContainer`}>
              <img
                className={`ml-1 locationInformation__img--size-150 locationInformation--image`}
                src={imageFileUrl}
                alt={"Result Image " + locationName}
              ></img>
            </div>
          )}
          <div className="flex-fill locationInformation__addressContainer">
            <div>
              {!isFavoriteSelected && (
                <div>
                  {distance >= 0 && !internetLocationText ? (
                    <div className="locationInformation__distanceContainer">
                      <div className="text-right locationInformation__radiusDistanceContainer">
                        <LabelWithIcon
                          title={
                            distanceUnit && (
                              <small>
                                {[formattedDistance, " ", distanceUnit]}
                              </small>
                            )
                          }
                          iconComponent={
                            <GeoAltFill
                              size={20}
                              className="mr-2"
                              role="img"
                              name="GeoAltFill"
                              title="distance"
                            />
                          }
                          btIcon={{ size: 20 }}
                          customIconUrl={defaultMapIcon}
                        ></LabelWithIcon>
                      </div>
                      {drivingDistance && distance > 0 && (
                        <div className="text-right locationInformation__drivingDistanceContainer">
                          <LabelWithIcon
                            title={`(${drivingDistance} by car)`}
                            btIcon={{
                              size: 18,
                            }}
                            url={drivingDistanceUrl}
                            target="_blank"
                            customIconUrl={CarRed}
                          ></LabelWithIcon>
                        </div>
                      )}
                    </div>
                  ) : (
                    internetLocationText && (
                      <div className="text-muted text-right locationInformation__internetLocContainer">
                        {defaultMapIcon && (
                          <img
                            className="mr-2"
                            style={{ height: 20, verticalAlign: "middle" }}
                            src={defaultMapIcon}
                            alt="icon"
                          />
                        )}
                        {InternetLocationIcon}
                        <small>{internetLocationText}</small>
                      </div>
                    )
                  )}
                </div>
              )}
              <div className="d-flex">
                <div className="locationInformation__nameContainer flex-fill">
                  {landingPageURL ? (
                    <>
                      <Link
                        to={landingPageURL}
                        target={showLandingPageLinkTarget}
                        onClick={(e) => {
                          e.stopPropagation();
                          trackEvent({
                            eventType: EVENT_LOG_TYPE.logSearch,
                            event: "click",
                            eventNameGA: "more_info_click",
                            responseType: SEARCH_RES_LOG_TYPE.moreInfoClicked,
                          });
                        }}
                      >
                        <span
                          name="location_name"
                          className="h5 d-inline-block locationInformation__nameWithLink"
                        >
                          {locationName}
                        </span>
                      </Link>
                      {claimThisListingUrl && (
                        <a
                          className="border-left ml-2 text-decoration-none"
                          href={claimThisListingUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          &nbsp; Claim This Listing
                        </a>
                      )}
                    </>
                  ) : (
                    <>
                      <h5
                        name="location_name"
                        className="locationInformation__nameWithoutLink d-inline-block"
                      >
                        {locationName}
                      </h5>
                      {claimThisListingUrl && (
                        <a
                          className="border-left ml-2 text-decoration-none"
                          href={claimThisListingUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          &nbsp; Claim This Listing
                        </a>
                      )}
                    </>
                  )}

                  {showReviews && (
                    <>
                      {reviewType === "BEReviews" && countTotalReviews > 0 && (
                        <OverallRating
                          allowWriteReviews={false}
                          countReviews={countTotalReviews}
                          showOverallRating={true}
                          rating={overallReviewsRating}
                          smallReviews={true}
                        />
                      )}

                      {reviewType === "Google" &&
                        googleMapIsReady &&
                        googlePlaceId && (
                          <GoogleReviews
                            GoogleplaceId={googlePlaceId}
                            googleScriptsReady={true}
                            showSmallHeader={true}
                            returnHeaderOnly={true}
                          />
                        )}

                      {reviewType === "Yelp" && yelpPlaceId && (
                        <YelpReviews
                          yelpPlaceID={yelpPlaceId}
                          returnHeaderOnly={true}
                          showSmallHeader={true}
                        />
                      )}
                    </>
                  )}
                </div>
                {locationTypeIconUrl && (
                  <div className="locationInformation__locationTypeContainer">
                    <img src={locationTypeIconUrl} alt={locationTypeName}></img>
                  </div>
                )}
              </div>
            </div>
            {showAddress && (
              <Address
                address1={address1}
                address2={address2}
                address3={address3}
                address4={address4}
                city={city}
                stateAbbr={stateAbbr}
                postalCode={postalCode}
                country={country}
                showViewPhoneNumber={showViewPhoneNumber}
                faxNumber={faxNumber}
              ></Address>
            )}
            {showAdministrativeArea && (
              <LabelWithIcon
                title={`${city ? city + ", " + stateAbbr : stateAbbr}`}
                btIcon={{
                  size: 18,
                }}
                iconComponent={
                  <GeoAltFill
                    size={20}
                    className="mr-2"
                    role="img"
                    title="address"
                  />
                }
              ></LabelWithIcon>
            )}

            <div className="my-2 d-flex flex-wrap locationInformation__buttonsContainer">
              {emailAddress && (
                <div className="mr-2 d-inline-block mt-2 locationInformation__emailButton">
                  <LinkButton
                    // icon={{ icon: "envelope", size: 20 }}
                    iconComponent={
                      <Envelope
                        size={20}
                        role="img"
                        name="Envelope"
                        title="email"
                      />
                    }
                    iconPosition={iconPositionDefault}
                    className="bullseye-btn-outline locationInformation__email"
                    url={`mailto:${emailAddress}`}
                    title={getClientTerm("defaultEmailTerm", "Email")}
                    captureEvents={{
                      eventType: EVENT_LOG_TYPE.logSearch,
                      event: "click",
                      responseType: SEARCH_RES_LOG_TYPE.emailClicked,
                      eventNameGA: "email_click",
                      extraParameters: { email: emailAddress },
                    }}
                  />
                </div>
              )}
              {
                <div className="mr-2 d-inline-block mt-2 locationInformation__directionsButton locationInformation__button--width">
                  <LinkButton
                    //icon={{ icon: "signpost-2", size: 20 }}
                    iconComponent={
                      <Signpost2
                        size={20}
                        role="img"
                        name="Signpost2"
                        title="directions"
                      />
                    }
                    iconPosition={iconPositionDefault}
                    className="bullseye-btn-outline locationInformation__directions"
                    url={buildGetDirectionsLink(
                      address1,
                      city,
                      stateAbbr,
                      postalCode,
                      country
                    )}
                    title={getClientTerm("defaultDirectionsTerm", "Directions")}
                    target="_blank"
                    captureEvents={{
                      eventType: EVENT_LOG_TYPE.logSearch,
                      event: "click",
                      responseType: SEARCH_RES_LOG_TYPE.directionsClicked,
                      eventNameGA: "directions_click",
                      extraParameters: {
                        url_link: buildGetDirectionsLink(
                          address1,
                          city,
                          stateAbbr,
                          postalCode,
                          country
                        ),
                      },
                    }}
                    rel="noopener noreferrer"
                  />
                </div>
              }
              {phone && (
                <div className="mr-2 d-inline-block mt-2 locationInformation__phoneButton">
                  <LinkButton
                    // icon={{ icon: "telephone-outbound", size: 20 }}
                    iconComponent={
                      <TelephoneOutbound
                        size={20}
                        role="img"
                        name="TelephoneOutbound"
                        title="phone number"
                      />
                    }
                    iconPosition={iconPositionDefault}
                    className="bullseye-btn-outline locationInformation__phone"
                    url={"tel:" + phone}
                    title={
                      !isMobile && isShowViewPhoneNumber ? "View Phone" : phone
                    }
                    isEventCancelable={!isMobile && isShowViewPhoneNumber}
                    captureEvents={{
                      eventType: EVENT_LOG_TYPE.logSearch,
                      event: "click",
                      eventNameGA:
                        !isMobile && isShowViewPhoneNumber
                          ? "view_phone_click"
                          : "phone_number_click",
                      responseType: SEARCH_RES_LOG_TYPE.phoneNumberClicked,
                      extraParameters: { phone },
                    }}
                    onClickButton={(e) => {
                      setIsShowViewPhoneNumber(false);
                    }}
                    ariaLabel={`Phone number for ${locationName} is ${phone}`}
                  />
                </div>
              )}
              {url && (
                <div className="mr-2 d-inline-block mt-2 locationInformation__urlButton">
                  <LinkButton
                    // icon={{ icon: "globe2", size: 20 }}
                    iconComponent={
                      <Globe2
                        size={20}
                        role="img"
                        name="Globe2"
                        title="website"
                      />
                    }
                    iconPosition={iconPositionDefault}
                    className="bullseye-btn-outline locationInformation__url"
                    url={url}
                    title={getClientTerm("defaultWebsiteTerm", "Website")}
                    target="_blank"
                    captureEvents={{
                      event: "click",
                      eventType: EVENT_LOG_TYPE.logSearch,
                      responseType: SEARCH_RES_LOG_TYPE.urlClicked,
                      eventNameGA: "location_url_click",
                      extraParameters: { url },
                    }}
                    rel="noopener noreferrer"
                  />
                </div>
              )}
            </div>
            {(contactName || contactPosition) && (
              <div className="mb-2 locationInformation__contactContainer">
                <b className="locationInformation__contactLabel">
                  {getClientTerm("defaultContactTerm", "Contact")}
                </b>{" "}
                <span className="locationInformation__contactName">
                  {contactName}
                </span>{" "}
                {contactPosition && (
                  <>
                    {contactName ? "," : ""}{" "}
                    <span className="locationInformation__contactPosition">
                      {contactPosition}
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Track>
    </>
  );
};

export default LocationInformation;
