import React, { useEffect, useState } from "react";
import TextInput from "../textInput";
import DropDownInput from "../dropDownInput";

const AddressInput = ({
  register,
  compact = false,
  small = false,
  errors,
  onChange,
  countriesAndStatesConfig,
  isShortAddress = false,
  configShortAddres = [],
  required = false,
  onCountryChange,
}) => {
  // Local States
  const [addressValues, setAddressValues] = useState({
    labelState: "State",
    labelZipCode: "Postal Code",
  });

  // Effects
  useEffect(() => {
    if (countriesAndStatesConfig?.labels) {
      let listLabels = countriesAndStatesConfig.labels.find(
        (x) => x.countryId === countriesAndStatesConfig.selectedCountryId
      );
      if (!listLabels) {
        listLabels = countriesAndStatesConfig.labels.find(
          (x) => x.countryId === 0
        );
      }

      setAddressValues({
        labelState: listLabels.labelState,
        labelZipCode: listLabels.labelZipCode,
      });
    }
  }, [countriesAndStatesConfig]);

  const changeCountry = (e) => {
    if (onCountryChange) {
      onCountryChange(parseInt(e.target.value, 10));
    }

    onChange(e);
  };

  return (
    <>
      {countriesAndStatesConfig?.countries?.length > 0 && (
        <>
          <DropDownInput
            label="Country"
            name="CountryId"
            small={small}
            options={countriesAndStatesConfig.countries}
            register={register({ required: "Country is required" })}
            required={true}
            errors={errors}
            onChange={changeCountry}
          />
          {(!isShortAddress ||
            (isShortAddress && configShortAddres.streetAddress)) && (
            <>
              <TextInput
                name={"Address1"}
                type={"text"}
                label={"Street Address"}
                compact={compact}
                small={small}
                register={register(
                  !isShortAddress
                    ? {
                        required: "Street Address is required",
                      }
                    : configShortAddres.streetAddress.validations
                )}
                required={
                  !isShortAddress
                    ? true
                    : configShortAddres?.streetAddress?.validations?.required
                }
                errors={errors}
                onChange={onChange}
              />
            </>
          )}
          {(!isShortAddress ||
            (isShortAddress && configShortAddres.addressLine2)) && (
            <>
              <TextInput
                name={"Address2"}
                type={"text"}
                label={"Address Line 2"}
                compact={compact}
                small={small}
                register={
                  !isShortAddress
                    ? register
                    : register(configShortAddres.addressLine2.validations)
                }
                required={
                  !isShortAddress
                    ? false
                    : configShortAddres?.addressLine2?.validations?.required
                }
                errors={errors}
                onChange={onChange}
              />
            </>
          )}
          {(!isShortAddress || (isShortAddress && configShortAddres.city)) && (
            <>
              <TextInput
                name={"City"}
                type={"text"}
                label={"City"}
                compact={compact}
                small={small}
                register={
                  !isShortAddress
                    ? register
                    : register(configShortAddres.city.validations)
                }
                required={
                  !isShortAddress
                    ? false
                    : configShortAddres?.city?.validations?.required
                }
                errors={errors}
                onChange={onChange}
              />
            </>
          )}
          {countriesAndStatesConfig.states[0]?.list &&
            countriesAndStatesConfig.states[0].list.length > 0 &&
            (!isShortAddress ||
              (isShortAddress && configShortAddres.state)) && (
              <>
                <DropDownInput
                  label={addressValues.labelState}
                  name={"StateAbbr"}
                  small={small}
                  options={countriesAndStatesConfig.states[0]?.list || []}
                  register={
                    !isShortAddress
                      ? register
                      : register(configShortAddres.state.validations)
                  }
                  required={
                    !isShortAddress
                      ? false
                      : configShortAddres?.state?.validations?.required
                  }
                  errors={errors}
                  onChange={onChange}
                  noSelectionText={`- Select a ${addressValues.labelState} -`}
                />
              </>
            )}
          <TextInput
            name={"PostalCode"}
            type={"text"}
            label={addressValues.labelZipCode}
            compact={compact}
            small={small}
            register={
              !isShortAddress
                ? register
                : register({
                    required: required
                      ? `${addressValues.labelZipCode} is required`
                      : false,
                  })
            }
            required={!isShortAddress ? false : required}
            errors={errors}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
};

export default AddressInput;
