import { useEffect, useState } from "react";
import { getEvent } from "../services/restEventService";

const defaultEventInformation = {
  ContactEmailAddress: "",
  EndDate: "",
  EndTime: "",
  StartDate: "",
  StartTime: "",
  OrganizerDescription: "",
  OrganizerName: "",
  Registration: "",
  RegistrationURL: "",
  EventTitle: "",
  EventId: 0,
  ThirdPartyID: "",
  EventDescription: "",
  EventImageURL: "",
  FacebookURL: "",
  TwitterURL: "",
  Attributes: [],
  Categories: [],
  EventLocations: [],
};

export function useEvent(eventId, thirdPartyId) {
  const [eventState, setEventState] = useState({
    loading: true,
    ...defaultEventInformation,
  });

  useEffect(() => {
    const getEventAsync = async () => {
      const event = await getEvent(eventId, thirdPartyId);

      setEventState({ loading: false, ...event });
    };

    if (thirdPartyId || eventId > 0) {
      getEventAsync();
    } else {
      setEventState({ loading: false, ...defaultEventInformation });
    }
  }, [thirdPartyId, eventId]);

  return eventState;
}
