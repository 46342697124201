// This function is used to install the Google Analytics tracking code on the page.
// It takes in two parameters:
// trackingId: a string or an array of strings representing the Google Analytics tracking codes (either GA3 or GA4)
// additionalConfigInfo: an optional object that can be used to pass additional configuration information to GA
export const install = function (trackingId) {
  // Create a scriptId variable to use as the ID for the script element that will be created
  const scriptId = "ga-gtag-internal";

  // Check if the scriptId already exists in the document
  if (document.getElementById(scriptId)) return;

  // Check if the trackingId is an array
  const isTrackingIdAnArray = Array.isArray(trackingId);

  // Get the head of the document
  const { head } = document;
  // Create a script element
  const script = document.createElement("script");

  // Set the script element's id, type, and src attributes
  script.id = scriptId;
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${
    isTrackingIdAnArray ? trackingId.join(",") : trackingId
  }`;

  // Insert the script element before the first child of the head
  head.insertBefore(script, head.firstChild);

  // Create a dataLayer array on the window object if it doesn't already exists
  window.dataLayer = window.dataLayer || [];

  // Call the gtag function with the "js" and current date arguments
  gtag("js", new Date());

  // Check if the trackingId is an array
  if (isTrackingIdAnArray) {
    // If it is, loop through the array and call the gtag function for each id with the additionalConfigInfo
    trackingId.forEach((id) => {
      // gtag("config", id, additionalConfigInfo);
      gtag("config", id, { debug_mode: true });

      console.log("Gtag initializing...", trackingId);
    });
  } else {
    // If it's not, call the gtag function with the trackingId and additionalConfigInfo
    // gtag("config", trackingId, additionalConfigInfo);
    gtag("config", trackingId, { debug_mode: true });

    console.log("Gtag initializing...", trackingId);
  }

  if (typeof window?.gtag === "undefined") {
    window.gtag = gtag;
  }
};

// Create a gtag function that pushes its arguments to the dataLayer array
export const gtag = function () {
  window.dataLayer.push(arguments);
};

export default gtag;
