import React, { useState } from "react";
import TextInput from "../textInput/textInput";

const PasswordInput = ({
  name,
  label,
  small = false,
  compact = false,
  register,
  errors,
  placeholder = "",
  required = false,
  onChange,
  validations,
  ...props
}) => {
  const [password, setPassword] = useState(null);
  const onChangePass = (e) => {
    setPassword(e.target.value);
    onChange(e);
  };
  return (
    <>
      <TextInput
        name={name}
        label={label}
        compact={compact}
        small={small}
        type={"password"}
        register={register(validations)}
        placeholder={placeholder}
        required={required}
        errors={errors}
        onChange={onChangePass}
        {...props}
      />
      <TextInput
        name={`re_${name}`}
        label={`Retype ${name}`}
        compact={compact}
        small={small}
        type={"password"}
        register={register({
          validate: (value) =>
            value === password || "The passwords do not match",
        })}
        errors={errors}
        onChange={onChange}
        {...props}
      />
    </>
  );
};

export default PasswordInput;
