import { FC } from "react";
import { ThreeDots } from "react-loader-spinner";

const BullseyeLoader: FC = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <ThreeDots color="#990000" height={100} width={100} />
    </div>
  );
};

export default BullseyeLoader;
