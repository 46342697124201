import React from "react";
import Label from "../label";
import ErrorDisplay from "../errorDisplay";
import PropTypes from "prop-types";

const TextInput = ({
  name,
  label,
  small = false,
  compact = false,
  register,
  errors,
  type = "text",
  placeholder = "",
  required = false,
  onChange,
  ...props
}) => {
  return (
    <div className="form-group" id={`attr_container_${name}`}>
      {!compact && <Label htmlFor={name} label={label} required={required} />}

      <input
        id={name}
        className={`form-control ${small ? "form-control-sm" : ""} ${
          errors[name] ? "is-invalid" : ""
        } `}
        type={type}
        name={name}
        placeholder={placeholder ? placeholder : compact ? label : ""}
        ref={register}
        onChange={onChange}
        aria-required={required || null}
        {...props}
      />
      {errors[name] && <ErrorDisplay errorMessage={errors[name]?.message} />}
    </div>
  );
};

TextInput.propTypes = {
  /** string of the Input Label */
  label: PropTypes.string.isRequired,
  /** name of the controll input */
  name: PropTypes.string.isRequired,

  /** text to put into the input like placeholder */
  placeholder: PropTypes.string,

  /** Parameter  */
  required: PropTypes.bool,
  /** Function to controll the state of the inputs */
  register: PropTypes.func.isRequired,
  /** string to display errors */
  errors: PropTypes.object,
  /** default value */
  value: PropTypes.string,
  /** type of the multiOption Radio or CheckBox */
  type: PropTypes.string,
  /** child component to display next to the input */
  children: PropTypes.node,

  props: PropTypes.object,
};

export default TextInput;
