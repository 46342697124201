import React from "react";

const homepage = process.env.REACT_APP_VIRTUAL_PATH;

const UnexpectedError = () => {
  return (
    <div className="text-center">
      <h1 className="display-5 mt-4 mb-5 bullseye-red font-weight-bold">
        An internal error has occurred.
      </h1>
      <p>Unfortunately, the page you have requested has an error.</p>

      <div className="my-5 d-block">
        <img
          src={`${homepage}images/bullseye_logo_outlined.svg`}
          alt="bullseye logo"
          style={{ height: 85 }}
        />
      </div>
    </div>
  );
};

export default UnexpectedError;
