import React from "react";
import "./cardWithImage.scss";
import PropTypes from "prop-types";

/** Simple component to show a list with text body and image to the left
 */
const CardWithImage = ({ title, body, image, fontColor, templateStyle = 1 }) => {
  return templateStyle === 1 ? (
    <div className="cardWithImage__container">
      <div className="bullseye-red h4">{title}</div>
      <div>
        {image && (
          <img
            className="cardWithImage__img--maxSize m-2 float-left"
            src={image}
            alt={title}
          ></img>
        )}
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
      </div>
    </div>
  ) : (
    templateStyle === 2 && (
      <div className="col-md-3 card_sales">
        <div className="col-md-12 text-center mt-1">
          <img
            src={image}
            alt={title}
            style={{ width: "125px", height: "125px" }}
          />
        </div>
        <div
          className="col-sm-12 text-center"
          style={{ color: fontColor.color }}
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
        <div className="col-md-12">
          <p className="text-center">{body}</p>
        </div>
      </div>
    )
  );
};
CardWithImage.propTypes = {
  /** Title to display on the card */
  title: PropTypes.string.isRequired,
  /** Text body to display */
  body: PropTypes.string,
  /** Image url to show on the card */
  image: PropTypes.string,
  /** What type of style */
  fontColor: PropTypes.object,
  templateStyle: PropTypes.number,
};

export default CardWithImage;
