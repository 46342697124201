import React from "react";
import ReactStars from "react-rating-stars-component";
import PropTypes from "prop-types";

const StartRating = ({ rating, isTittle = false }) => {
  return (
    <ReactStars
      count={5}
      value={rating}
      size={isTittle ? 35 : 25}
      activeColor={
        window.bullseyeLocationsConfig?.reviewStarsActiveColor
          ? window.bullseyeLocationsConfig.reviewStarsActiveColor
          : "#ffd700"
      }
      edit={false}
    />
  );
};
StartRating.propTypes = {
  /** number of the rating to display   */
  rating: PropTypes.number.isRequired,
  /** set true to display on tittle otherwise it will display in normal size  */
  isTittle: PropTypes.bool,
};
export default StartRating;
