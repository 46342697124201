import React from "react";
import StartRating from "./startRating";
import RatingService from "./ratingService";
import PropTypes from "prop-types";
import checked from "../../assets/images/checked.png";

const CardRating = ({
  author_name,
  relative_time_description,
  rating,
  text,
  services,
  recommend,
  reviewConfiguration,
}) => {
  return (
    <div className="border-bottom cardRating__container">
      <div className="my-3 d-flex flex-column flex-md-row">
        <div className="pr-2 w-100 w-md-75">
          <h5 className="mb-0">
            {reviewConfiguration.reviewerName && (
              <>
                <span data-name="author_name">{author_name}</span>
              </>
            )}

            <small className="ml-2 text-muted" data-name="description">
              {relative_time_description}
            </small>
          </h5>
          {reviewConfiguration.overallRating && <StartRating rating={rating} />}
          {reviewConfiguration.comments && <p data-name="comments">{text}</p>}
        </div>

        {services && (
          <div
            style={{ minWidth: 160 }}
            className="cardRating__servicesContainer"
          >
            {services
              .filter((x) => x.rating)
              .map((e, index) => {
                let categoryShow = false;
                switch (index) {
                  case 0:
                    categoryShow = reviewConfiguration.ratingCategoryOne;
                    break;
                  case 1:
                    categoryShow = reviewConfiguration.ratingCategoryTwo;
                    break;
                  case 2:
                    categoryShow = reviewConfiguration.ratingCategoryThree;
                    break;
                  default:
                    categoryShow = false;
                }
                return (
                  <div key={index}>
                    {categoryShow && (
                      <RatingService
                        name={e.name}
                        rating={e.rating}
                        reviewConfiguration={reviewConfiguration}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {reviewConfiguration.recommendationQuestion && recommend && (
        <div className="pr-2 w-100 mb-3 cardRating__recomendation">
          <img className="recommene-field" src={checked} alt="checked" />
          <span className="ml-2"> Yes, I recommend this location!</span>
        </div>
      )}
    </div>
  );
};
CardRating.propTypes = {
  /** Author name of the review */
  author_name: PropTypes.string.isRequired,

  /** description of the date the review was made */
  relative_time_description: PropTypes.string.isRequired,

  /** rating number that was given in the review */
  rating: PropTypes.number.isRequired,
  /** Text description of the review */
  text: PropTypes.string,
  /** Array of the rating of the services */
  services: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of the service */
      name: PropTypes.string.isRequired,
      /** rating of the service */
      rating: PropTypes.number.isRequired,
    })
  ),
};
export default CardRating;
