import React from "react";
import EventLandingPage from "./eventLandingPage";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useClientConfiguration } from "../../hooks/useClientConfiguration";
import { setSecurityParameters } from "../../services/httpService";
import { useEvent } from "../../hooks/useEvent";

const cssBaseURL = process.env.REACT_APP_BULLSEYE_RESOURCE;

const GetEventInformation = ({ crumbs }) => {
  const { interfaceName, eventId } = useParams();
  const clientConfig = useClientConfiguration(interfaceName, "");
  let eventIdentifier = null;

  if (
    clientConfig.configClientSearch.clientId > 0 &&
    clientConfig.configClientSearch.apiKey
  ) {
    setSecurityParameters(
      clientConfig.configClientSearch.clientId,
      clientConfig.configClientSearch.apiKey
    );
    eventIdentifier = eventId;
  }

  const eventInformation = useEvent(eventIdentifier);

  return (
    !clientConfig.loading &&
    !eventInformation.loading && (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`${cssBaseURL}/${clientConfig.configClientSearch.clientId}/landing/${interfaceName}.min.css`}
          />

          <link
            rel="stylesheet"
            type="text/css"
            href={`${cssBaseURL}/${clientConfig.configClientSearch.clientId}/landing/${interfaceName}.advanced.min.css`}
          />
          <link
            rel="canonical"
            href={`${window.location.href.slice(
              0,
              window.location.href.indexOf("?")
            )}`}
          />
        </Helmet>
        <EventLandingPage
          clientConfig={clientConfig}
          event={eventInformation}
          crumbs={crumbs}
        ></EventLandingPage>
      </>
    )
  );
};

export default GetEventInformation;
