import { ApplicationState } from ".";

type ApplicationActionType = {
  type:
    | "[application] - SetConfiguration"
    | "[application] - SetFavorites"
    | "[application] - addFavoriteLocation"
    | "[application] - removeFavoriteLocation"
    | "[application] - localStorageAvailable";
  payload?: string | boolean;
  locations?: Record<string, string[]>;
};

export const applicationReducer = (
  state: ApplicationState,
  action: ApplicationActionType
): ApplicationState => {
  switch (action.type) {
    case "[application] - SetConfiguration":
      return {
        ...state,
        googleGATrackingCode: (action.payload || "").toString(),
      };
    case "[application] - SetFavorites":
      return {
        ...state,
        currentFavorites: action.locations ?? {},
      };
    case "[application] - addFavoriteLocation":
      if (action.payload) {
        const JSONFavorites = JSON.parse(action.payload?.toString());

        if (JSONFavorites) {
          const interfaceId = JSONFavorites.interfaceId;
          const locationId = JSONFavorites.locationId;

          const updatedCurrentFavorites = { ...state.currentFavorites };

          if (updatedCurrentFavorites[interfaceId]) {
            updatedCurrentFavorites[interfaceId].push(locationId);
          } else {
            updatedCurrentFavorites[interfaceId] = [locationId];
          }

          localStorage.setItem(
            "locationsFavorites",
            JSON.stringify(updatedCurrentFavorites)
          );

          return {
            ...state,
            currentFavorites: updatedCurrentFavorites,
          };
        }
      }

      return state;
    case "[application] - removeFavoriteLocation":
      if (action.payload) {
        const JSONFavorites = JSON.parse(action.payload?.toString());

        if (JSONFavorites) {
          const interfaceId = JSONFavorites.interfaceId;
          const locationId = JSONFavorites.locationId;

          const updatedCurrentFavorites = { ...state.currentFavorites };

          if (updatedCurrentFavorites[interfaceId]) {
            const index =
              updatedCurrentFavorites[interfaceId].indexOf(locationId);
            if (index !== -1) {
              updatedCurrentFavorites[interfaceId].splice(index, 1);
            }
          }

          localStorage.setItem(
            "locationsFavorites",
            JSON.stringify(updatedCurrentFavorites)
          );

          return {
            ...state,
            currentFavorites: updatedCurrentFavorites,
          };
        }
      }
      return state;
    case "[application] - localStorageAvailable":
      return {
        ...state,
        localStorageAviable: Boolean(action.payload),
      };
    default:
      return state;
  }
};
