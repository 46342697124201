import React, { FC } from "react";
import PropTypes from "prop-types";
import LabelWithIcon from "../labelWithIcon/labelWithIcon";
import { useTracking } from "react-tracking";
import { EventLogType, SearchResponseLogType } from "../../utils/constants2";
import { isMobile } from "react-device-detect";

export interface Props {
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  stateAbbr?: string;
  postalCode?: string;
  country?: string;
  phone?: string;
  shortAddress?: boolean;
  showViewPhoneNumber?: boolean;
  faxNumber?: string;
}

/** Component to display locations address including the country and phone
 *  TODO: support for different address format city, state postal code for different
 *  countries other than USA
 */
const Address: FC<Props> = ({
  address1,
  address2,
  address3,
  address4,
  city,
  stateAbbr,
  postalCode,
  country,
  phone,
  shortAddress,
  showViewPhoneNumber,
  faxNumber,
}) => {
  const { trackEvent } = useTracking();

  return (
    <address className="mb-1">
      <ul className="list-unstyled mb-1">
        {address1 && <li data-name="address1">{address1}</li>}
        {!shortAddress && address2 && <li data-name="address2">{address2}</li>}
        {address3 && (
          <li data-name="address3" hidden>
            {address3}
          </li>
        )}
        {address4 && (
          <li data-name="address4" hidden>
            {address4}
          </li>
        )}
        <li>
          {city && <span data-name="city">{city}</span>}
          {city && (stateAbbr || postalCode) ? "," : ""}{" "}
          {stateAbbr && <span data-name="stateAbbr">{stateAbbr}</span>}{" "}
          {postalCode && <span data-name="postalCode">{postalCode}</span>}
        </li>
        {!shortAddress && (
          <li>{country && <span data-name="country">{country}</span>}</li>
        )}
        {!shortAddress && phone && (
          <li>
            <LabelWithIcon
              containerClass="mt-1"
              name="phoneNumber"
              url={"tel:" + phone}
              title={phone}
              btIcon={{ btIconName: "telephone-outbound-fill", size: 16 }}
              hiddenText={!isMobile && showViewPhoneNumber ? "View Phone" : ""}
              customEventNameGA={
                !isMobile && showViewPhoneNumber
                  ? "view_phone_click"
                  : "phone_number_click"
              }
              onClickCapture={(data: HTMLSpanElement) =>
                trackEvent({
                  eventType: EventLogType.logSearch,
                  event: "click",
                  eventNameGA: data,
                  responseType: SearchResponseLogType.phoneNumberClicked,
                  extraParameters: { phone },
                })
              }
              labelClass={undefined}
              customIconUrl={undefined}
            ></LabelWithIcon>
          </li>
        )}
        {faxNumber && (
          <li data-name="faxNumber" hidden>
            {faxNumber}
          </li>
        )}
      </ul>
    </address>
  );
};

Address.propTypes = {
  /** Extra information like suite, office, etc */
  address2: PropTypes.string,
  /** City */
  city: PropTypes.string,
  /** Two letter state or province abbreviation */
  stateAbbr: PropTypes.string,
  /** Postal Code, Zip Code, etc. */
  postalCode: PropTypes.string,
  /** Full Name for the country */
  country: PropTypes.string,
  /** If this prop is present the phone will show as link with tel: */
  phone: PropTypes.string,
  /** if shortAddress = true, hides Address 2, Country and Phone fields*/
  shortAddress: PropTypes.bool.isRequired,
};

// Specifies the default values for props:
Address.defaultProps = {
  shortAddress: false,
};

export default Address;
