import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./breadcrumbMenu.scss";

import PropTypes from "prop-types";
/**
 * Component build a breadCrum menu.
 */
const BreadcrumbMenu = ({ items }) => {
  return (
    <>
      <Breadcrumb className="breadcrumbMenu__container">
        {items.map((item, index) =>
          item.isExternalLink ? (
            <Breadcrumb.Item key={index} href={item.url}>
              {item.displayText}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item
              key={index}
              linkAs={Link}
              linkProps={{ to: item.url }}
              active={items.length === index + 1}
            >
              {item.displayText}
            </Breadcrumb.Item>
          )
        )}
      </Breadcrumb>
    </>
  );
};
BreadcrumbMenu.propTypes = {
  /** List of menu items with at least the following attributes */
  items: PropTypes.arrayOf(
    /** Atributes per item on the location list */
    PropTypes.shape({
      /** option to know where to url return  */
      url: PropTypes.string,
      /** Text to display on the menu */
      displayText: PropTypes.string.isRequired,
      /** the link is external from this site */
      isExternalLink: PropTypes.bool,
    })
  ),
};

export default BreadcrumbMenu;
