import React, { useEffect, useRef, useState } from "react";
import "google-maps-reviews/google-maps-reviews.css";
import Reviews from "../reviews";
import PropTypes from "prop-types";
import OverallRating from "../reviews/overallRating";

/**
 * Google Reviews Components, shows all the reviews from a location with his google key
 */
const GoogleReviews = ({
  title,
  GoogleplaceId,
  googleScriptsReady = false,
  returnHeaderOnly = false,
  showSmallHeader = false,
}) => {
  //
  const [reviews, setReviews] = useState({
    rating: 5,
    countReviews: 0,
    reviews: [],
    status: false,
    urlMoreReviews: "#",
  });

  var callback = function (place, status) {
    // eslint-disable-next-line no-undef
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const reviews = {
        rating: place.rating,
        countReviews: place.user_ratings_total,
        reviews: place.reviews,
        status: true,
        urlMoreReviews: place.url,
      };

      setReviews(reviews);
    }
  };

  const googleReviewContainer = useRef();

  useEffect(() => {
    if (!googleScriptsReady || !GoogleplaceId) return;
    // initiate a Google Places Object
    // const mydiv = document.getElementById("bu-google-reviews");

    // eslint-disable-next-line no-undef
    const service = new google.maps.places.PlacesService(
      googleReviewContainer.current
    );

    const request = {
      placeId: GoogleplaceId,
      fields: ["url", "rating", "user_ratings_total", "reviews"],
    };

    service.getDetails(request, callback);
  }, [GoogleplaceId, googleScriptsReady]);

  return (
    <>
      <div
        ref={googleReviewContainer}
        id="bu-google-reviews"
        className="d-none"
      ></div>
      {reviews.status &&
        reviews.countReviews > 0 &&
        googleScriptsReady &&
        (!returnHeaderOnly ? (
          <Reviews title={title} listReviews={reviews} />
        ) : (
          <OverallRating
            countReviews={reviews.countReviews}
            rating={reviews.rating}
            showOverallRating={true}
            smallReviews={showSmallHeader}
            urlMoreReviews={reviews.urlMoreReviews}
            sourceTypeName={"Google"}
          />
        ))}
    </>
  );
};
GoogleReviews.propTypes = {
  /** label title to the card */
  title: PropTypes.string,
  /** Google Place Id for the location */
  GoogleplaceId: PropTypes.string.isRequired,
  /** Prevent loading Google Reviews without Google SDK script ready  */
  googleScriptsReady: PropTypes.bool.isRequired,
};
export default GoogleReviews;
