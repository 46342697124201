import React from "react";
import PropTypes from "prop-types";
import ShowcaseCard from "./showcaseCard";

/** Showcase could display  */
const Showcase = ({
  title,
  items,
  itemCssClass,
  maxItemsToDisplay,
  cssColor,
}) => {
  return (
    <div className="showcase__container">
      <div className="bullseye-red h4">{title}</div>
      <div className="row">
        {items &&
          items.length > 0 &&
          items
            .slice(0, maxItemsToDisplay)
            .map((item) => (
              <ShowcaseCard
                key={item.id}
                title={item.title}
                icon={item.iconUrl}
                body={item.body}
                links={item.links}
                itemCssClass={itemCssClass + " flex-grow-1"}
                iconSize={item.iconSize}
                cssColor={cssColor}
              />
            ))}
      </div>
    </div>
  );
};

Showcase.propTypes = {
  /** Title for the card */
  title: PropTypes.string,
  /** Array of Items to display in columns */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Icon URL to show on top */
      icon: PropTypes.string,
      /** Main card title */
      title: PropTypes.string,
      /** Description in a paragraph */
      body: PropTypes.string,
      /** Links to display in the bottom of the Card */
      links: PropTypes.arrayOf(
        PropTypes.shape({
          /** URL (Open in the same tab) */
          url: PropTypes.string.isRequired,
          /** Display text for the Link */
          title: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  /** Extra CSS class you can add to the individuals cards */
  itemCssClass: PropTypes.string,
  /** Max items to display at the same time, default value is 3 */
  maxItemsToDisplay: PropTypes.number.isRequired,
  /**Change the color of event depend of the theme */
  cssColor: PropTypes.string,
};

// Specifies the default values for props:
Showcase.defaultProps = {
  maxItemsToDisplay: 3,
};

export default Showcase;
